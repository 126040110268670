import React, { useEffect, useState } from "react";

import { Button, Img, List, SeekBar, Text } from "components";
import Header from "components/Header";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProfilePage = () => {
  const navigate = useNavigate();
  const storedUserData = localStorage.getItem("userData");
  const IqScore = localStorage.getItem("IqScore");
  const userData = JSON.parse(storedUserData);
  const [gameResult, setGameResult] = useState([]);
  const userName = userData ? userData.name : "";
  const userId = userData ? userData._id : "";
  const [gameCounts, setGameCounts] = useState({});
  const [tictoe, setTictoe] = useState([]);
  const [Iq, setIq] = useState([]);
  const [memory, setMemory] = useState([]);
  let IQData = JSON.parse(IqScore ?? "{}");

  const handleGenerateReport = async () => {
    try {
      let url = `https://brainwise.training/api//users/generateReport/${userName}/${
        IqScore?.iqScore ?? 8
      }`;
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error creating payment:", error);
    }
  };

   const downloadReporPDF = () => {
    const link = document.createElement('a');
    link.href = '/assets/Comprehensive_Cognitive_Skills_Report.pdf'; // Update the path to your PDF file
    link.download = 'Comprehensive_Cognitive_Skills_Report.pdf'; // Set the desired file name
    link.click();
  };

  const getResult = async () => {
    try {
      let url = `https://brainwise.training/api//users/results/${userId}`;
      const response = await fetch(url);
      const result = await response.json();
      setGameResult(result.gameResults);
    } catch (error) {
      console.error("Error getting game results:", error);
    }
  };

  useEffect(() => {
    getResult();
  }, []);

  const calculateGameCounts = () => {
    let newGameCounts = { ...gameCounts }; // Make a copy of the existing state

    if (Array.isArray(gameResult)) {
      gameResult.forEach((game) => {
        const { game_type, Score } = game;

        if (!newGameCounts[game_type]) {
          newGameCounts[game_type] = {
            played: 1,
            won: Score > 0 ? 1 : 0,
          };
        } else {
          newGameCounts[game_type].played += 1;

          if (Score > 0) {
            newGameCounts[game_type].won += 1;
          }
        }
      });
    } else {
      console.error("gameResults is not an array:", gameResult);
    }

    console.log("Game Counts:", newGameCounts);
    setGameCounts(newGameCounts); // Update the state with the new game counts
  };

  useEffect(() => {
    calculateGameCounts();
  }, [gameResult]);

  useEffect(() => {
    // Inside this useEffect, update state only if it's necessary
    setIq(gameCounts["IQ Test"]);
    setMemory(gameCounts["Memory Card"]);
    setTictoe(gameCounts["Tic-Tac-Toe"]);
  }, [gameCounts]); // Add gameCounts as a dependency

  const IQWin = Iq?.won;
  const IQPlay = Iq?.played;
  const TicPlay = tictoe?.played;
  const TicToywon = tictoe?.won;
  const memoryWon = memory?.won;
  const memoryplayed = memory?.played;

  const generateCertificate = async () => {
    try {
      const response = await fetch(
        `https://brainwise.training/api//users/generateCertificate/${userName}`
      );
      const data = await response.json();
      const link = document.createElement('a');
      link.href = data.image;
      link.setAttribute('download', 'certificate.png');
      
      // Append anchor element to the body
      document.body.appendChild(link);

      // Click the link to trigger download
      link.click();

      // Remove the anchor element
      document.body.removeChild(link);;
    } catch (error) {
      console.error("Error generating certificate:", error);
    }
  };

  return (
    <>
      <Header className="bg-gray-200 flex sm:flex-col flex-row sm:gap-5 items-center justify-center md:px-5 shadow-bs2 w-full" />

      <div className="bg-white-A700 mt-4 md:mt-0 flex flex-col font-inter items-center justify-start mx-auto pb-[57px] w-full">
        <div className="flex flex-col items-center justify-start w-full">
          <div className="flex flex-col gap-[34px] items-center justify-start w-full">
            <div
              className="flex md:flex-col flex-row gap-[22px] items-center justify-start max-w-[1191px] mx-auto
          
            md:px-5 w-full"
            >
              <div className="flex md:flex-1 flex-col gap-[43px] items-center justify-start w-[72%] md:w-full">
                <div className="flex flex-col relative w-full">
                  <div className="h-[399px] md:h-[600px] mx-auto w-full ">
                    <Img
                      className="h-[399px] m-auto object-cover rounded-[10px] w-full"
                      src="images/img_rectangle50.png"
                      alt="rectangleFifty"
                    />
                  </div>
                  <div className="absolute top-0 bg-indigo-400_aa flex flex-col h-max items-start justify-center m-auto p-8 sm:px-5 rounded-[10px] w-full">
                    <div className="flex flex-row gap-5 items-center justify-start mb-[54px] mt-[3px] w-[41%] md:w-full">
                      <div className="border border-solid border-white-A700 flex flex-col h-[77px] items-center justify-start p-[3px] rounded-[38px] w-[77px]">
                        <Img
                          className="h-[70px] md:h-auto rounded-[50%] w-[70px]"
                          src="images/img_ellipse16.png"
                          alt="ellipseSixteen"
                        />
                      </div>
                      <Text
                        className="leading-[33.00px] sm:text-[34px] md:text-[40px] text-[44px] text-white-A700"
                        size="txtInterSemiBold44WhiteA700"
                      >
                        <span className="md:text-[23px] sm:text-[21px] text-white-A700 font-inter text-left text-[25px] font-normal">
                          <>
                            Wellcome
                            <br />
                          </>
                        </span>
                        <span className="md:text-[26px] sm:text-2xl text-white-A700 font-inter text-left text-[28px] font-medium">
                          {userName}
                        </span>
                      </Text>
                    </div>
                  </div>
                  <div className="absolute bottom-0 left-8 sm:left-3 bg-white-A700 flex flex-col items-center justify-start mt-[-51px] mx-auto p-[22px] sm:px-5 rounded-[10px] shadow-bs5 w-[93%] z-[1]">
                    <div className="flex sm:flex-col flex-row sm:gap-10 justify-between mb-1.5 w-[98%] md:w-full">
                      <div className="flex flex-col items-start justify-start">
                        <Text
                          className="text-gray-900_01 text-lg"
                          size="txtInterMedium18Gray90001"
                        >
                          Email
                        </Text>
                        <Text
                          className="mt-[3px] text-gray-700 text-lg"
                          size="txtInterRegular18Gray700"
                        >
                          {userData ? userData.email : ""}
                        </Text>
                        <Text
                          className="mt-[13px] text-gray-900_01 text-lg"
                          size="txtInterMedium18Gray90001"
                        >
                          Plan
                        </Text>
                        <Text
                          className="text-gray-700 text-lg"
                          size="txtInterRegular18Gray700"
                        >
                          {userData ? userData.user_plan : ""}
                        </Text>
                        <Text
                          className="mt-3.5 text-gray-900_01 text-lg"
                          size="txtInterMedium18Gray90001"
                        >
                          User Id
                        </Text>
                        <Text
                          className="text-gray-700 text-lg"
                          size="txtInterRegular18Gray700"
                        >
                          XXXXXXXXXXXXXXXXXX
                        </Text>
                      </div>
                      <div className="flex flex-col items-start justify-start sm:mt-0 mt-[9px] w-[31%] sm:w-full">
                        <Text
                          className="text-base text-gray-900_01"
                          size="txtInterRegular16Gray90001"
                        >
                          Iq Game
                        </Text>
                        <div className="flex flex-row gap-3 items-start justify-between w-full">
                          <SeekBar
                            inputValue={[50]}
                            trackColors={["", "#e1e1e1"]}
                            thumbClassName="h-2.5 bg-gray-300_03 flex rounded-[50%] justify-center items-center outline-none"
                            className="flex h-2.5 my-[3px] rounded-[5px]"
                            trackClassName="h-[3px] flex rounded-[5px] w-full"
                          />{" "}
                          <Text
                            className="text-gray-700 text-sm"
                            size="txtInterRegular14"
                          >
                            {IQWin}/{IQPlay}
                          </Text>
                        </div>
                        <Text
                          className="mt-[22px] text-base text-gray-900_01"
                          size="txtInterRegular16Gray90001"
                        >
                          Tic-Tac-Toe
                        </Text>
                        <div className="flex flex-row gap-3 items-start justify-between w-full">
                          <SeekBar
                            inputValue={[50]}
                            trackColors={["", "#e1e1e1"]}
                            thumbClassName="h-2.5 bg-gray-300_03 flex rounded-[50%] justify-center items-center outline-none"
                            className="flex h-2.5 my-[3px] rounded-[5px]"
                            trackClassName="h-[3px] flex rounded-[5px] w-full"
                          />{" "}
                          <Text
                            className="text-gray-700 text-sm"
                            size="txtInterRegular14"
                          >
                            {TicToywon}/ {TicPlay}
                          </Text>
                        </div>
                        <Text
                          className="mt-[22px] text-base text-gray-900_01"
                          size="txtInterRegular16Gray90001"
                        >
                          Memory Card
                        </Text>
                        <div className="flex flex-row gap-3 items-start justify-between w-full">
                          <SeekBar
                            inputValue={[50]}
                            trackColors={["", "#e1e1e1"]}
                            thumbClassName="h-2.5 bg-gray-300_03 flex rounded-[50%] justify-center items-center outline-none"
                            className="flex h-2.5 my-[3px] rounded-[5px]"
                            trackClassName="h-[3px] flex rounded-[5px] w-full"
                          />{" "}
                          <Text
                            className="text-gray-700 text-sm"
                            size="txtInterRegular14"
                          >
                            {memoryWon}/{memoryplayed}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <List
                  className="sm:flex-col flex-row gap-[22px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-center w-full"
                  orientation="horizontal"
                >
                  <div className="bg-white-A700 flex flex-1 flex-col gap-[17px] items-center justify-start pb-[19px] rounded-[10px] shadow-bs5 w-full">
                    <Img
                      className="h-[244px] md:h-auto object-cover rounded-[10px] w-full"
                      src="images/img_rectangle51.png"
                      alt="rectangleFiftyOne"
                    />
                    <div className="flex flex-col items-center justify-start w-[62%] md:w-full">
                      <Button
                        className="cursor-pointer font-semibold min-w-[166px] rounded-[22px] text-center text-lg"
                        shape="round"
                        size="md"
                        variant="gradient"
                        color="deep_purple_900_purple_400"
                        onClick={() => {
                          navigate("/SudokuPage");
                        }}
                      >
                        Play
                      </Button>
                    </div>
                  </div>
                  <div className="bg-white-A700 flex flex-1 flex-col gap-[17px] items-center justify-start pb-[19px] rounded-[10px] shadow-bs5 w-full">
                    <Img
                      className="h-[244px] md:h-auto object-cover rounded-[10px] w-full"
                      src="images/img_rectangle53.png"
                      alt="rectangleFiftyThree"
                    />
                    <div className="flex flex-col items-center justify-start w-[62%] md:w-full">
                      <Button
                        className="cursor-pointer font-semibold min-w-[166px] rounded-[22px] text-center text-lg"
                        shape="round"
                        size="md"
                        variant="gradient"
                        color="deep_purple_900_purple_400"
                        onClick={() => {
                          navigate("/TicTacGamePage");
                        }}
                      >
                        Play
                      </Button>
                    </div>
                  </div>
                  <div className="bg-white-A700 flex flex-1 flex-col gap-[17px] items-center justify-start pb-[19px] rounded-[10px] shadow-bs5 w-full">
                    <Img
                      className="h-[244px] md:h-auto object-cover rounded-[10px] w-full"
                      src="images/img_rectangle55.png"
                      alt="rectangleFiftyFive"
                    />
                    <div className="flex flex-col items-center justify-start w-[62%] md:w-full">
                      <Button
                        className="cursor-pointer font-semibold min-w-[166px] rounded-[22px] text-center text-lg"
                        shape="round"
                        size="md"
                        variant="gradient"
                        color="deep_purple_900_purple_400"
                        onClick={() => {
                          navigate("/MemoryCardPage");
                        }}
                      >
                        Play
                      </Button>
                    </div>
                  </div>
                </List>
              </div>
              <div className="flex md:flex-1 flex-col gap-[21px] items-center justify-start w-[27%] md:w-full">
                <div className="bg-white-A700 flex flex-col items-center justify-start p-3 rounded-lg shadow-bs5 w-full">
                  <div className="flex flex-col gap-[9px] justify-start mb-2 w-full">
                    <Text
                      className="ml-4 md:ml-[0] text-[22px] text-black-900 sm:text-lg md:text-xl"
                      size="txtInterMedium22"
                    >
                      Result
                    </Text>
                    <div className="flex flex-col items-center justify-start w-full">
                      <div className="bg-white-A700 flex flex-col items-center justify-end p-[15px] rounded-lg shadow-bs5 w-full">
                        <div className="flex flex-col gap-[23px] items-center justify-start w-3/5 md:w-full">
                          <Img
                            className="h-[103px]"
                            src="images/img_reportsicon1.svg"
                            alt="reportsiconOne"
                          />
                          <div className="flex flex-col items-center justify-start w-full">
                            <Button
                              className="cursor-pointer font-semibold min-w-[155px] rounded-[17px] text-center text-sm"
                              size="sm"
                              variant="gradient"
                              color="deep_purple_900_purple_400"
                              onClick={downloadReporPDF}
                            >
                              Download Report
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-start justify-between mt-3 w-[95%] md:w-full">
                        <Text
                          className="text-center text-gray-700 text-sm"
                          size="txtInterRegular14"
                        >
                          IQ Score
                        </Text>
                        <Text
                          className="h-5 text-base text-gray-900_02 text-right"
                          size="txtInterMedium16"
                        >
                          {IQData.iqScore}
                        </Text>
                      </div>
                      <div className="flex flex-row items-start justify-between mt-[5px] w-[95%] md:w-full">
                        <Text
                          className="text-gray-700 text-sm"
                          size="txtInterRegular14"
                        >
                          Time
                        </Text>
                        <Text
                          className="text-base text-gray-900_02 text-right"
                          size="txtInterMedium16"
                        >
                          {IQData.duration}
                        </Text>
                      </div>
                      <div className="bg-white-A700 flex flex-col items-center justify-end mt-[19px] p-[15px] rounded-lg shadow-bs5 w-full">
                        <div className="flex flex-col gap-[13px] items-center justify-start w-[79%] md:w-full">
                          <Img
                            className="h-[72px]"
                            src="images/img_thumbsup.svg"
                            alt="thumbsup"
                          />
                          <Text
                            className="leading-[15.00px] text-center text-gray-700 text-sm"
                            size="txtInterRegular14"
                          >
                            <>
                              Lorem Ipsum is simply dummy <br />
                              of the printing
                            </>
                          </Text>
                          <div className="flex flex-col items-center justify-start w-[82%] md:w-full">
                            <Button
                              className="cursor-pointer font-semibold min-w-[166px] rounded-[17px] text-center text-sm"
                              size="sm"
                              variant="gradient"
                              color="deep_purple_900_purple_400"
                              onClick={generateCertificate}
                            >
                              Download Certificate
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white-A700 flex flex-col items-center justify-end p-[15px] rounded-lg shadow-bs5 w-full">
                  <div className="flex flex-col items-start justify-start mt-1.5 w-[95%] md:w-full">
                    <div className="flex flex-col items-center justify-start w-full">
                      <div className="flex flex-col items-start justify-start w-full">
                        <Text
                          className="text-base text-gray-900_01"
                          size="txtInterMedium16Gray90001"
                        >
                          Categories 1
                        </Text>
                        <div className="h-3 mt-[9px] overflow-hidden relative w-full">
                          <div className="w-full h-full absolute bg-gray_300_03 rounded-[6px]"></div>
                          <div
                            className="h-full absolute bg-gradient-to-r from-[#2510c0] to-[#9a10c0]  rounded-[6px]"
                            style={{ width: "54%" }}
                          ></div>
                        </div>
                        <div className="flex flex-row items-start justify-between ml-0.5 md:ml-[0] mt-[5px] w-full">
                          <Text
                            className="text-gray-700 text-xs"
                            size="txtInterRegular12"
                          >
                            Low
                          </Text>
                          <Text
                            className="text-center text-gray-700 text-xs"
                            size="txtInterRegular12"
                          >
                            Medium
                          </Text>
                          <Text
                            className="text-gray-700 text-right text-xs"
                            size="txtInterRegular12"
                          >
                            High
                          </Text>
                        </div>
                      </div>
                    </div>
                    <Text
                      className="mt-[17px] text-base text-gray-900_01"
                      size="txtInterMedium16Gray90001"
                    >
                      Categories 2
                    </Text>
                    <div className="h-3 mt-[9px] overflow-hidden relative w-full">
                      <div className="w-full h-full absolute bg-gray_300_03 rounded-[6px]"></div>
                      <div
                        className="h-full absolute bg-gradient-to-r from-[#ff3635] to-[#fc9719]  rounded-[6px]"
                        style={{ width: "77%" }}
                      ></div>
                    </div>
                    <div className="flex flex-row items-start justify-between ml-0.5 md:ml-[0] mt-[5px] w-full">
                      <Text
                        className="text-gray-700 text-xs"
                        size="txtInterRegular12"
                      >
                        Low
                      </Text>
                      <Text
                        className="text-center text-gray-700 text-xs"
                        size="txtInterRegular12"
                      >
                        Medium
                      </Text>
                      <Text
                        className="text-gray-700 text-right text-xs"
                        size="txtInterRegular12"
                      >
                        High
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;

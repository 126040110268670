import React, { useEffect, useState } from "react";
import "./index.css";
import dropin from "braintree-web-drop-in";
import { useNavigate } from "react-router-dom";

export default function BraintreeDropIn(props) {
  const { amount ,isAmountSelected } = props;
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");

  const [braintreeInstance, setBraintreeInstance] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const initializeBraintree = (clientToken) =>
    dropin.create(
      {
        // insert your tokenization key or client token here
        authorization: clientToken,
        container: "#braintree-drop-in-div",
      },
      function (error, instance) {
        if (error) console.error(error);
        else setBraintreeInstance(instance);
      }
    );

  const fetchData = async () => {
    const response = await fetch(
      "https://brainwise.training/api//payment/braintree/getToken"
    );
    const data = await response.json();

    if (braintreeInstance) {
      braintreeInstance.teardown().then(() => {
        initializeBraintree(data.clientToken);
      });
    } else {
      initializeBraintree(data.clientToken);
    }
  };

  const handelPaymentSubmit = async () => {
    if (braintreeInstance) {
      braintreeInstance.requestPaymentMethod((error, payload) => {
        if (error) {
          console.error(error);
        } else {
          const paymentMethodNonce = payload.nonce;
          console.log("payment method nonce", payload.nonce);
          handelCheckOut(paymentMethodNonce);
          // TODO: use the paymentMethodNonce to
          //  call you server and complete the payment here

          // ...
        }
      });
    }
  };

  const handelCheckOut = async (paymentMethodNonce) => {
    try {
      const response = await fetch(
        "https://brainwise.training/api//payment/braintree/checkout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount,
            paymentMethodNonce,
            userEmail,
            userName,
            plan: "Gold",
            planExpire: "12/12/2024",
          }),
        }
      );

      const data = await response.json();
      console.log("onPaymentCompleted", data);
      // onPaymentCompleted(data);
      navigate(`/payment/success/${data.userID}/${data.paymentId}`);
      // alert(`Payment completed with nonce=${paymentMethodNonce}`);
    } catch (error) {
      console.error("Error creating payment:", error);
    }
  };

  const disabledButton = !braintreeInstance || userName === "" || userEmail === "" || !isAmountSelected

  return (
    <div style={{ display: "block" }} className="m-2">
      <div id="braintree-drop-in-div" />
      <div
        style={{ display: braintreeInstance === undefined ? "none" : "block" }}
        className="inputBoxOuter "
      >
        <div className="form__field-group">
          <label>
            <div className="label_text_Input">Full Name</div>
            <div className="input_field_C">
              <input
                className="input_field_C"
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
          </label>
        </div>
        <div className="form__field-group">
          <label>
            <div className="label_text_Input">E-mail</div>
            <div className="input_field_C">
              <input
                className="input_field_C"
                type="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
          </label>
        </div>
      </div>
      <button
        className={`braintreePayButton bg-green-500 text-4xl  text-white my-4 font-medium py-3 px-12 rounded w-1/5 md:w-full  ${
          disabledButton
            ? "cursor-not-allowed opacity-50" 
            : "hover:bg-green-700"
        }`}
        type="primary"
        disabled={disabledButton}
        onClick={handelPaymentSubmit}
      >
        {"Pay"}
      </button>
    </div>
  );
}

import { Button, Img } from "components";
import React, { useEffect, useState } from "react";

const MCQQuestion = ({ no = 1, options = [], handleOptionSelection }) => {
  const [selected, setSelected] = useState("");

  useEffect(()=>{
    setSelected('')
  },[no ])

  const handleOptionChange = (option) => {
    handleOptionSelection(option);
    setSelected(option);
  };
  return (
    <>
      <div className="flex md:flex-col flex-row gap-[34px] items-center justify-evenly  w-full">
        <div className="bg-deep_purple-50 flex md:flex-1 flex-col items-center justify-end p-2 rounded-[10px] w-[49%] md:w-full">
          <Img
            className="h-4/5 w-[91%]"
            src={`images/IQquestions/Kausimas ${no}.svg`}
            alt="layerx00201"
          />
        </div>

        <div className="w-[49%] md:w-full  p-5 sm:p-2 ">
          <div className="grid grid-cols-2  gap-5 items-center justify-start">
            {options.map((option, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleOptionChange(option)}
                >
                  <Img
                    className={`${
                      selected === option ? "bg-lime-300" : "bg-deep_purple-50"
                    } h-full w-full px-2 py-1`}
                    src={`images/IQquestions/Kausimas ${no} ${option}.svg`}
                    alt="layerx00201_One"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MCQQuestion;

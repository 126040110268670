import React, { useEffect } from "react";
import { Heading } from "components/Heading";
import { TextArea } from "components/TextArea";
import { Text } from "components";
import { useNavigate } from "react-router-dom";
import { Button } from "components";
import { Img } from "components";
import Header from "components/Header";
import Footer from "components/Footer";

const ContactPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <>
            <Header className="bg-gray-200 flex sm:flex-col flex-row sm:gap-5 items-center justify-center md:px-5 shadow-bs2 w-full" />

      <div className="bg-white-A700 flex flex-col font-inter items-center justify-start mx-auto w-full">
        <div className="bg-gradient1  flex flex-col items-center justify-end p-7 sm:px-5 w-full">
          <div className="flex sm:flex-col flex-row md:gap-10 items-center justify-between max-w-[1175px] mx-auto md:px-5 w-full">
            <div className="flex flex-col gap-2 items-start justify-start">
              <Text
                className="sm:text-[32px] md:text-[38px] text-[42px] text-black-900"
                size="txtInterSemiBold42"
              >
                Contact
              </Text>
              <div className="flex flex-row font-poppins items-center justify-between w-full">
                <Text
                  className="text-black-900_01 text-xl"
                  size="txtPoppinsRegular20Black90001"
                  onClick={() => navigate("/")}
                >
                  HOME
                </Text>
                <Img
                  className="h-[11px]"
                  src="images/img_arrowright.svg"
                  alt="arrowright"
                />
                <Text
                  className="text-deep_purple-800_02 text-xl"
                  size="txtPoppinsMedium20"
                >
                  Contact Us
                </Text>
              </div>
            </div>
            <Img
              className="h-[243px] md:h-auto object-cover"
              src="images/img_2998315ai1.png"
              alt="2998315aiOne"
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-start w-full gap-[51px]">
          <div className="flex flex-row sm:flex-col justify-start   w-full mt-12 gap-[25px] max-w-[1169px]">
            <div className="flex flex-row justify-center   sm:w-[100%] w-[74%] p-5 bg-white-A700 drop-shadow-xl rounded-lg">
              <div className="flex flex-col items-center justify-start w-full gap-[22px] my-[11px]">
                <div className="flex flex-row justify-start w-full gap-5">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    className="w-[49%] p-4 border-gray-300 border border-solid !rounded-[10px]"
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    className="w-[49%] p-4 border-gray-300 border border-solid !rounded-[10px]"
                  />
                </div>
                <div className="flex flex-row justify-start w-full gap-5">
                  <input
                    type="number"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    className="w-[49%] p-4 border-gray-300 border border-solid !rounded-[10px]"
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Id"
                    className="w-[49%] p-4 border-gray-300 border border-solid !rounded-[10px]"
                  />
                </div>
                <TextArea
                  name="link_pages"
                  placeholder="Write your message"
                  className="w-full text-gray-500 font-medium"
                />
                <Button
                  size="md"
                  variant="gradient"
                  color="deep_purple_900_purple_400"
                  className="font-semibold min-w-[196px] rounded-[27px]"
                >
                  Send
                </Button>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start  sm:w-[100%] w-1/4 gap-5">
              <div className="flex flex-col items-center justify-start w-full gap-5">
                <div className="flex flex-col items-center justify-center w-full gap-[15px] p-[17px] bg-white-A700 drop-shadow-xl rounded-lg">
                  <Button
                    size="md"
                    variant="gradient"
                    color="deep_purple_900_purple_400"
                    className="font-semibold w-[50px] rounded-[27px]"
                  >
                    <Img src="images/img_group_20.svg" />
                  </Button>
                  <div className="flex flex-col items-center justify-start w-[65%] gap-2">
                    <Text
                      size="lg"
                      as="p"
                      className="!text-blue_gray-700 text-center"
                    >
                      +91 97XXXXXX76
                    </Text>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-start w-full gap-5 p-[15px] bg-white-A700 drop-shadow-xl rounded-lg">
                  <Button
                   size="md"
                   variant="gradient"
                   color="deep_purple_900_purple_400"
                   className="font-semibold w-[50px] h-[50px] mt-[5px] rounded-full"
                   
                  >
                    <Img src="images/img_group_318.svg" />
                  </Button>
                  <div className="flex flex-row justify-center w-[84%]">
                    <div className="flex flex-col items-center justify-start w-full gap-[5px]">
                      <Text
                        size="lg"
                        as="p"
                        className="!text-blue_gray-700 text-center"
                      >
                        example12@gmail.com
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center w-full gap-[9px] p-4 bg-white-A700 drop-shadow-xl rounded-lg">
                <Button
                 size="md"
                 variant="gradient"
                 color="deep_purple_900_purple_400"
                 className="font-semibold w-[50px] h-[50px] mt-[5px] rounded-full"
                >
                  <Img src="images/img_group_16.svg" />
                </Button>
                <div className="flex flex-row justify-center w-full mb-1.5">
                  <Text
                    size="lg"
                    as="p"
                    className="!text-blue_gray-700 text-center"
                  >
                    1028 New Mexico 48, Alto, New York, United States
                  </Text>
                </div>
              </div>
            </div>
          </div>
          {/* <Img src="images/img_rectangle_168.png" alt="image" className="w-full object-cover max-w-[1170px]" /> */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158858.5851960224!2d-0.2664050245106056!3d51.52852620113951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sin!4v1708972536357!5m2!1sen!2sin"
            style={{ width: "100vw ", height: 450, border: 0 }}
          ></iframe>

          {/* <div className="flex flex-row justify-center w-full">
            <div className="flex flex-col items-center justify-start w-full">
              <Heading size="2xl" as="h2" className="text-center">
                IQTest
              </Heading>
              <div className="flex flex-row justify-between w-1/5 mt-[23px]">
                <Button className="w-12 space-x-2 mx-0.5" >
                  <Img src="images/img_group_214.svg" />
                </Button>
                <Button className="w-12 space-x-2 mx-0.5">
                  <Img src="images/img_group_213.png" />
                </Button>
                <Button className="w-12 space-x-2 mx-0.5">
                  <Img src="images/img_group_212.svg" />
                </Button>
                <Button className="w-12 space-x-2 mx-0.5">
                  <Img src="images/img_group_211.svg" />
                </Button>
              </div>
              <div className="flex flex-row justify-center w-[31%] mt-[30px]   sm:ml-[-78px] ml-[20px]">
                <div className="flex flex-row justify-start items-start w-full gap-1">
                  <div className="h-[22px] w-[100%] relative">
                    <Text
                      size="md"
                      as="p"
                      className="justify-center w-max left-0 bottom-0 right-0 top-0 m-auto !text-black-900_01 !font-medium absolute"
                    >
                      <span className='sm:mx-0.5  mx-1.5'>Home</span>
                      <span className='sm:mx-0.5  mx-1.5'>About</span>
                      <span className='sm:mx-0.5  mx-1.5'>IQ Test</span>
                      <span className='sm:mx-0.5  mx-1.5'>Contact</span> 
                    </Text>
                  
                  </div>
                </div>
              </div>
              <Text
                size="md"
                as="p"
                className="w-[51%] mt-3.5 !text-gray-800_01 text-center !leading-[27px]"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur ut massa dui. Aenean posuere turpis sed enim lacinia
                eleifend.
              </Text>
              <div className="flex flex-row justify-center w-full mt-[25px]">
                <div className="flex flex-row justify-center w-full p-[15px] bg-indigo-50_01">
                  <Text
                    size="md"
                    as="p"
                    className="mt-[3px] ml-[442px] mr-[441px] !text-gray-800_01 text-center"
                  >
                    © 2023 IQ TESTS. ALL RIGHTS RESERVED.
                  </Text>
                </div>
              </div>
            </div>
          </div> */}
           <Footer/>
        </div>
      </div>
    </>
  );
};

export default ContactPage;

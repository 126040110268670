import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Heading } from "components/Heading";

import { Button, Img, Line, List, Text } from "components";
import Header from "components/Header";
import Footer from "components/Footer";

const PrivicyPolicy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header className="bg-gray-200 flex sm:flex-col flex-row sm:gap-5 items-center justify-center md:px-5 shadow-bs w-full" />

      <div className="bg-white-A700 flex flex-col font-inter items-center justify-start mx-auto w-full">
        <div className="bg-gradient1  flex flex-col items-center justify-end p-7 sm:px-5 w-full">
          <div className="flex sm:flex-col flex-row md:gap-10 items-center justify-between max-w-[1175px] mx-auto md:px-5 w-full">
            <div className="flex flex-col gap-2 items-start justify-start">
              <Text
                className="sm:text-[32px] md:text-[38px] text-[42px] text-black-900"
                size="txtInterSemiBold42"
              >
                PRIVACY POLICY
              </Text>
            </div>
            <Img
              className="h-[243px] md:h-auto object-cover"
              src="images/img_2998315ai1.png"
              alt="2998315aiOne"
            />
          </div>
        </div>
        <div
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            maxWidth: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "12px",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <div
            className="mx-auto"
            style={{ maxWidth: "90%", marginTop: "20px", marginBottom: "20px" }}
          >
            <h1 className="mt-3" style={{ fontWeight: "bold" }}>
              PRIVACY POLICY
            </h1>
            <h3 className="mt-3" style={{ fontWeight: "bold" }}>
              Effective date: February 21, 2024
            </h3>
            <p className="mb-3 mt-2 text-black ">
              Vabankas, UAB (“we”, “us”, “Company”) is the controller of the
              personal data you provide to us. We operate the brainwise.training
              website (“Website”) to provide our users (“you” and “your”) with
              information about our services (“Services”).
            </p>
            <p className="text-black">
              If you have any questions about the Terms or our Services, please
              contact us at support@Brainwise.training .
            </p>
            <p className="mt-3 text-black">
              Please read the Terms carefully. By accessing or using our
              Services, you acknowledge that you accept and agree to be bound by
              the Terms. If you do not agree to these Terms, including the
              governing law and class action waiver in Section 7 (Governing law
              and disputes), do not use Brainwise.training. Your continued use
              of Brainwise.training will confirm your acceptance of these Terms.
            </p>
            <p className="mt-3 text-black">
              {" "}
              This Privacy Policy explains the way We collect and store Your
              information. By providing Personal Information or User Content to
              Us, You consent to Our collection, use, and disclosure of Your
              Personal Information in accordance with this Privacy Policy and
              any other arrangements that apply between Us. We may change our
              Privacy Policy from time to time by publishing changes to it on
              the Website. We encourage You to check our Website periodically to
              ensure that You are aware of Our current Privacy Policy. The last
              update date of this Privacy Policy is posted at the bottom of the
              Privacy Policy.
            </p>
            <p className="mt-3 text-black">
              Please note that this Privacy Policy applies only to the Website.
              This Website may contain links to other websites not operated or
              controlled by us. The links from this Website do not imply that we
              endorse or reviewed the such third-party websites.
            </p>

            <h1 className="mt-3" style={{ fontWeight: "bold" }}>
              IF YOU DO NOT AGREE TO THE TERMS, YOU MAY NOT ACCESS OR USE THE
              SERVICES.
            </h1>
            <h3 className="mt-3" style={{ fontWeight: "bold" }}>
              I.What information we collect?
            </h3>
            <h3 className="mt-3">
              We collect and use the following information
            </h3>
            <div>
              <h3 className="mt-3">1.Personal Data</h3>

              <p className="mt-3 text-black">
                {" "}
                When you use our Website, we may collect certain personally
                identifiable information about you (“Personal Data”), i.e. when
                you voluntarily provide such information such as when you use
                our Services, contact us with inquiries, etc. Wherever we
                collect Personal Data we provide a link to this Privacy Policy.
                We reserve our right to introduce minor changes to the Terms
                that do not materially influence your rights (e.g., technical
                corrections or description of a new feature) without notice.
              </p>
            </div>
            <div>
              <h3 className="mt-3">2.Usage Data</h3>

              <p className="mt-3 text-black">
                We may also collect information that your browser sends whenever
                you visit our Website or when you access the Website by or
                through a mobile device ("Usage Data").
              </p>
              <p className="mt-3 text-black">
                This Usage Data may include information such as IP addresses,
                the type of browser and device you use, the web page you visited
                before coming to our sites, and identifiers associated with your
                devices.
              </p>
              <p className="mt-3 text-black">
                When you access the Website by or through a mobile device, this
                Usage Data may include information such as the type of mobile
                device you use, your mobile device unique ID, the IP address of
                your mobile device, your mobile operating system, the type of
                mobile Internet browser you use, unique device identifiers and
                other diagnostic data.
              </p>
              <p className="mt-3 text-black">
                Please note that such information, which is collected passively
                using various technologies, cannot presently be used to
                specifically identify you. This Website may use such information
                and pool it with other information to track, for example, the
                total number of visitors to our Website, the number of visitors
                to each page of our Website, the domain names of our visitors'
                Internet service providers, and how our users use and interact
                with the Services.
              </p>
            </div>
            <div>
              <h3 className="mt-3">3.Tracking Cookies Data</h3>

              <p className="mt-3 text-black">
              When you visit any Website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. 
              </p>
              <p className="mt-3 text-black">
              You may, at any time, prevent the setting of cookies through our Website by means of a corresponding setting of the internet browser used, and may thus permanently deny the setting of cookies. Furthermore, already set cookies may be deleted at any time via an internet browser or other software programs.
              </p>
              <p className="mt-3 text-black">
                When you access the Website by or through a mobile device, this
                Usage Data may include information such as the type of mobile
                device you use, your mobile device unique ID, the IP address of
                your mobile device, your mobile operating system, the type of
                mobile Internet browser you use, unique device identifiers and
                other diagnostic data.
              </p>
              <p className="mt-3 text-black">
              If you disable cookies off, some features will be disabled that make your Website experience more efficient and some of our services will not function properly. However, you can still place orders. If you deactivate the setting of cookies in the internet browser used, not all functions of our Website may be entirely usable.
              </p>



              <div class="relative overflow-x-auto mt-[20px]">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                   
                </th>
                <th scope="col" class="px-6 py-3">
                </th>
                <th scope="col" class="px-6 py-3">
                </th>
                <th scope="col" class="px-6 py-3">
                    
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                If you disable cookies off, some
                </th>
                <td class="px-6 py-4">
                Collection 
                </td>
                <td class="px-6 py-4">
                Collection means
                </td>
                <td class="px-6 py-4">
                Legal basis for collection and 
                </td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
               features will be disabled that make your   {/*Website experience more efficient and some of our services will not function properly. However, you can still place orders. If you deactivate the setting of cookies in the internet browser used, not all functions of our Website may be entirely usable.
Data category */}
                </th>
                <td class="px-6 py-4">
                means 
                </td>
                <td class="px-6 py-4">
                  ''
                </td>
                <td class="px-6 py-4">
                processing                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Your name, email
                </th>
                <td class="px-6 py-4">
                You provide it to us 
                </td>
                <td class="px-6 py-4">
                We use it for account creation 
                </td>
                <td class="px-6 py-4">
                Performance of a contract with you                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                Your name, email, country, contents
                <br/> of communications with
                <br/> support team 
                </th>
                <td class="px-6 py-4">
                Some of it you provide<br/> to us & some of it is<br/> collected automatically
                </td>
                <td class="px-6 py-4">
                To communicate with you;<br/> to notify you about<br/> changes to the Services;<br/> to send you technical<br/> notice about your<br/> account / subscription,<br/> security alerts, <br/>administrative messages <br/>(may be sent via<br/> email, in-app or <br/>push notification).<br/> You may opt out <br/>of push notifications <br/>by changing the settings <br/>on your mobile device. 
                </td>
                <td class="px-6 py-4">
                It is our legal obligation <br/>to keep you informed<br/> about your subscription <br/>and your account and<br/> otherwise in our <br/>legitimate interests of<br/> keeping you informed <br/>about your account                </td>
            </tr>
            <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                The photos you upload to the app
               
                </th>
                <td class="px-6 py-4">
                You provide it to us 
                </td>
                <td class="px-6 py-4">
                We allow you to upload <br/>photos of yourself<br/> and your partner so <br/>that we can provide <br/>the Services you have ordered
                </td>
                <td class="px-6 py-4">
                Contract
                </td>
            </tr>
        </tbody>
    </table>
</div>


              <p className="mt-3 text-black"> <span style={{fontWeight:'bold'}}>II</span>   We use Personal Data for various purposes stated in this Privacy Policy. If you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. For instance, if you contact us by email, we will use the Personal Data you provide to answer your question or resolve your problem. Also, if you provide Personal Data in order to obtain access to the Services, we will use your Personal Data to provide you with access to such services and to monitor your use of such services. We  and our subsidiaries and affiliates (“Related Companies”) may also use your Personal Data and other personally non-identifiable information collected through the Website to help us improve the content and functionality of the Website, to better understand our users and to improve the Services. We and our Related Companies may use this information to communicate with you regarding the Services.</p>
            </div>
            
            <div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>III.Personal Data retention</h3>
    <p className="mt-3 text-black"> We retain your Personal Data for as long as your account is active or as needed for the purposes of processing. At any time, you can delete your account, as well as the Personal Data associated with it, by sending a request to support@brainwise.training. Please note that we still may retain certain data about you if so needed due to applicable legislation requirements, any potential or ongoing dispute resolution, or in order to enforce our rights.</p>
   
  
 
</div>  
           
                 
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>IV.Are you required to provide us Personal Data?</h3>
    <p className="mt-3 text-black"> 
Please note that in order for us to contact you after making a request through the Website, you must provide us with the abovementioned Personal Data.</p>
   
  
 
</div>  
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>V.Where do we get your Personal Data from (where does the data come from)?</h3>
    <p className="mt-3 text-black"> 
    We receive data directly from you, i.e. when you provide your Personal Data through our Website.   
  </p>
 
</div>     
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>VI.Transfer of Data</h3>
    <p className="mt-3 text-black"> 
    The information you provide to us, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction. By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy.
    <br/>
    Please note that we will not share your Personal Data with others, without your permission, except for the following purposes:
  </p>
  <p  className="mt-3 text-black">1.Others working for us (service providers)
We can use certain trusted third parties (for example, providers of customer support and IT services) to help us improve our Website. These third parties may access information only to perform tasks on our behalf in compliance with this Privacy Policy, and we'll remain responsible for their handling of your information per our instructions.</p>
<p className="mt-3 text-black">2.Related Companies
We may also share your Personal Data with our Related Companies for purposes consistent with this Privacy Policy.</p>
<p className="mt-3 text-black">3.Business transfers
As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets.</p>

<p className="mt-3 text-black">4.Law & Order 
We may disclose your information to third parties if we determine that such disclosure is reasonably necessary to (a) comply with the law; (b) protect any person from death or serious bodily injury; (c) prevent fraud or abuse of us or our users; or (d) protect our property rights. 
We do not transfer your Personal Data to third country or international organization, i.e. outside the European Union, unless required to do so by law or by the courts or as required for one of the purposes set out in this Policy.
 </p>




</div>       
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>VII.Data Subjects’ Rights</h3>
<h3 className="mt-3" >Regarding the submission of your Personal Data via our Website and according to the GDPR, you have the following rights:</h3>

    <p className="mt-3 text-black">·the right to be informed about data processing;</p>
    <p className="mt-3 text-black">·the right of access;</p>
    <p className="mt-3 text-black">·the right to rectification;</p>
    <p className="mt-3 text-black">·the right to erasure (‘right to be forgotten’);</p>
    <p className="mt-3 text-black">·the right to data portability;
</p>

    <p className="mt-3 text-black">·the right to restriction of processing;</p>
    <p className="mt-3 text-black">·the right to object</p>


  <p className="mt-3 text-black">You also have the right to withdraw your consent (if you have given one) for the processing of your Personal Data at any time. You can withdraw your consent at any time by writing to us at support@brainwise.training. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal.
In order to exercise abovementioned rights, you must submit a request by the manner described in Clause XI in writing and in a way that identifies you, i.e. affirming your identity. We do not process requests without the ability to identify the applicant.</p>
  
 
</div>      

<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>VIII.Your rights under the California Consumer Privacy Act</h3>
    <p className="mt-3 text-black"> To the extent that the California Consumer Privacy Act (“CCPA”) is applicable to either Company or You: both parties agree to comply with all of its obligations under the CCPA. In relation to any communication of 'Personal Data' as defined by the CCPA, the parties agree that no monetary or other valuable consideration is being provided for such Personal Data and therefore neither party is 'selling', as defined by the CCPA, Personal Data to the other party, unless specifically agreed for and consented to by the parties.</p>
    <p className="mt-3 text-black">The principal rights You have under the CCPA include but are not limited to: the right to know the Personal Data We collect from You; the right to request the deletion of Your Personal Data; the right not to be discriminated against for having exercised Your rights under the CCPA; the right to opt out from the collection and/or sale of Your Personal Data; and the right to access Your Personal Data. </p>
    <p className="mt-3 text-black">Both parties agree to notify the other immediately if it receives any complaint, notice, or communication that directly or indirectly relates to either party's compliance with the CCPA. Specifically, We shall notify You within 10 working days if We receive a verifiable consumer request under the CCPA.</p>
  
 
</div>
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>IX.Security measures</h3>
    <p className="mt-3 text-black"> We use reasonable and appropriate information security safeguards to help keep your personal data secure and in an effort to protect it from accidental loss and unauthorized access, use, alteration and disclosure. Unfortunately, the transmission of information via the Internet is not completely secure. Although we take measures to do our best to protect your personal data, we cannot guarantee the security of the collected information transmitted to or through our Services or an absolute guarantee that such information may not be accessed, disclosed, altered, or destroyed. Any transmission of your personal data is at your own risk. We are not responsible for the circumvention of security measures contained in the App.</p>
    <p className="mt-3 text-black">If you want to report a security incident related to our Services, please contact us at support@brainwise.training. </p>
  
 
</div>
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>X.Making a complaint</h3>
    <p className="mt-3 text-black">If You have a reason to believe We have breached any applicable law, or You wish to make a complaint about the manner by which We have handled Your Personal Information, You may contact us by the manner described in Clause XI. We will acknowledge Your complaint and respond to You within a reasonable period of time. If You think that We have failed to resolve the complaint satisfactorily, We will provide You with information about the further steps You can take..</p>
  
 
</div>
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>XI.Contact us</h3>
    <p className="mt-3 text-black">For further information about Our Privacy Policy or practices, or to access or correct Your Personal Information, or to make a complaint, please contact us by sending an email to support@brainwise.training.</p>
  
 
</div>
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>XII.Changes </h3>
    <p className="mt-3 text-black">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
You are advised to review this Privacy Policy periodically for any changes. This Privacy Policy was last updated on the date indicated above. Your continued use of the Website after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.</p>
  
 
</div>

          
           
           
           
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default PrivicyPolicy;

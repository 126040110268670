import { SET_PAYMENT_SUCCESS, SET_PAYMENT_FAILURE, SET_PAYMENT_REQUEST } from "../actionsTypes";

const initialState = {
    isPaymentCompleted: false,
    isPaymentRequest: false,
    paymentRequestBody: null,
    error: null,
    data: null
};

const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAYMENT_SUCCESS:
            return {
                ...state,
                isPaymentCompleted: true,
                isPaymentRequest: false,
                data: action.payload,
                error: null,
            };
        case SET_PAYMENT_FAILURE:
            return {
                ...state,
                isPaymentCompleted: false,
                isPaymentRequest: false,
                user: null,
                error: action.payload,
            };
        case SET_PAYMENT_REQUEST:
            return {
                ...state,
                isPaymentCompleted: false,
                isPaymentRequest: true,
                paymentRequestBody: action.payload,
                error: null,
            };
        default:
            return state;
    }
};

export default paymentReducer;


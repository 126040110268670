import React, { useEffect } from "react";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS, SET_PAYMENT_REQUEST } from "../../redux/actionsTypes";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId, paymentId } = useParams();

  useEffect(() => {
    console.log(userId, paymentId, "fetch data");
    handlePaymentVerify();
  }, []);

  const handlePaymentVerify = async () => {
    try {
      // if (auth) {
      let iqTestResult =
        localStorage.getItem("IqScore") ??
        '{"duration":"","attempted":"","iqScore":"","total":0}';
      // Make a POST request to your payment/create API endpoint
      const response = await fetch("https://brainwise.training/api//users/loginByToken", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers if required
        },
        // Add any request payload if needed
        body: JSON.stringify({
          userId,
          paymentId,
          iqTestResult,
        }),
      });
      // console.log(response)

      if (response.ok) {
        const data = await response.json();
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data,
        });
        localStorage.setItem("userData", JSON.stringify(data));
        localStorage.setItem("loginToken", data.token);
        navigate("/profile");
      }
      // Handle the response (you may need to customize this based on your API)
      // console.log(data , "data")
      // } else {

      //   navigate("/login");
      // }
      // setPaymentStatus(data.status); // Set the payment status based on the API response
    } catch (error) {
      console.error("Error creating payment:", error);

      // setPaymentStatus("error");
    }
  };
  return (
    <div className="payment-success-screen">
      <div className="success-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="72"
          height="72"
          fill="#28a745"
          className="bi bi-check-circle"
          viewBox="0 0 16 16"
        >
          <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm4.354 5.354a.5.5 0 0 0-.708-.708L7 9.293 5.354 7.646a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l6-6z" />
        </svg>
      </div>
      <div className="success-message">Payment successful!</div>
    </div>
  );
};

export default PaymentSuccess;

import React from "react";

const sizeClasses = {
  txtInterRegular18Gray700: "font-inter font-normal",
  txtPoppinsRegular20Black90001: "font-normal font-poppins",
  txtInterSemiBold44WhiteA700: "font-inter font-semibold",
  txtInterRegular20: "font-inter font-normal",
  txtInterSemiBold47: "font-inter font-semibold",
  txtInterMedium21: "font-inter font-medium",
  txtInterSemiBold46: "font-inter font-semibold",
  txtInterMedium22: "font-inter font-medium",
  txtInterSemiBold24: "font-inter font-semibold",
  txtInterSemiBold29: "font-inter font-semibold",
  txtInterMedium16Gray90001: "font-inter font-medium",
  txtInterSemiBold32: "font-inter font-semibold",
  txtInterBold80: "font-bold font-inter",
  txtInterSemiBold30: "font-inter font-semibold",
  txtInterRegular16Gray90001: "font-inter font-normal",
  txtPoppinsRegular31: "font-normal font-poppins",
  txtInterRegular22: "font-inter font-normal",
  txtInterMedium18Gray90001: "font-inter font-medium",
  txtInterRegular25: "font-inter font-normal",
  txtInterSemiBold30Gray90003: "font-inter font-semibold",
  txtInterMedium18: "font-inter font-medium",
  txtInterRegular22WhiteA700: "font-inter font-normal",
  txtInterRegular18Gray80001: "font-inter font-normal",
  txtInterSemiBold59: "font-inter font-semibold",
  txtInterSemiBold34: "font-inter font-semibold",
  txtInterExtraBold46WhiteA700: "font-extrabold font-inter",
  txtInterMedium16: "font-inter font-medium",
  txtInterSemiBold17: "font-inter font-semibold",
  txtInterMedium15: "font-inter font-medium",
  txtInterSemiBold40: "font-inter font-semibold",
  txtInterSemiBold34Gray90003: "font-inter font-semibold",
  txtPoppinsRegular20Gray500: "font-normal font-poppins",
  txtPoppinsMedium20: "font-medium font-poppins",
  txtInterExtraBold46: "font-extrabold font-inter",
  txtInterMedium20: "font-inter font-medium",
  txtInterSemiBold44: "font-inter font-semibold",
  txtPoppinsRegular20: "font-normal font-poppins",
  txtInterSemiBold42: "font-inter font-semibold",
  txtInterRegular18WhiteA700: "font-inter font-normal",
  txtInterRegular12: "font-inter font-normal",
  txtInterRegular14: "font-inter font-normal",
  txtInterRegular15: "font-inter font-normal",
  txtInterRegular16: "font-inter font-normal",
  txtInterRegular18: "font-inter font-normal",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };

import React, { useEffect, useState } from "react";
import "./index.css";
import { ApiEndPoints } from "NetworkCall";
import { ApiServices } from "NetworkCall";
const initialBoard = Array(9).fill(null);

const TicTacGame = (props) => {
  const [isAutomationDone, setIsAutomationDone] = useState(false);
  const [board, setBoard] = useState(initialBoard);
  const [xIsNext, setXIsNext] = useState(true);
  const [timerRunning, setTimerRunning] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [isGameFinished, setIsGameFinished] = useState(false);

  const storedUserData = localStorage.getItem("userData");
  const userName = storedUserData ? JSON.parse(storedUserData).name : "";
  const userId = storedUserData ? JSON.parse(storedUserData)._id : "";

  const handleClick = (index) => {
    if (calculateWinner(board) || board[index]) {
      return;
    }

    const newBoard = [...board];
    newBoard[index] = xIsNext ? "X" : "O";

    setBoard(newBoard);
    setXIsNext(!xIsNext);
  };

  const handleUserClick = (index) => {
    setIsAutomationDone(false);
    handleClick(index);
  };

  const renderSquare = (index) => (
    <button
      className="square w-32 h-32 font-bold text-8xl"
      onClick={() => handleUserClick(index)}
    >
      {board[index]}
    </button>
  );

  const winner = calculateWinner(board);
  const status = winner
    ? `Winner: ${winner}`
    : `Next player: ${xIsNext ? "X" : "O"}`;

  useEffect(() => {
    if (!timerRunning && !winner) {
      // Start the timer when the game starts
      setTimerRunning(true);
    }

    // If it's O's turn (second player) and the game is not over, simulate their move
    if (!xIsNext && !winner && !isAutomationDone) {
      setIsAutomationDone(true);
      // Generate a random move for the second player
      const emptySquares = board.reduce((acc, value, index) => {
        if (!value) {
          acc.push(index);
        }
        return acc;
      }, []);

      const randomIndex = Math.floor(Math.random() * emptySquares.length);
      const randomMove = emptySquares[randomIndex];

      // Simulate the second player's move after a short delay
      setTimeout(() => {
        handleClick(randomMove);
      }, 1000);
    }

    // If there is a winner, stop the timer
    if (winner) {
      setTimerRunning(false);
      setIsGameFinished(true);
    }
  }, [board, xIsNext, winner, isAutomationDone, timerRunning]);

  useEffect(() => {
    // Update the timer every second
    const timerInterval = setInterval(() => {
      if (timerRunning) {
        setSeconds((prevSeconds) => prevSeconds + 1);
        if (seconds === 60) {
          setSeconds(0);
          setMinutes((prevMinutes) => prevMinutes + 1);
        }
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timerRunning, seconds, minutes]);

  const handleReset = () => {
    handleFormSubmit(winner === "X" ? 1 : 0);
    setBoard(initialBoard);
    setXIsNext(true);
    setIsAutomationDone(false);
    setTimerRunning(false);
    setIsGameFinished(false)
    setSeconds(0);
    setMinutes(0);
    props.onReset();
  };

  const handleFormSubmit = async (score) => {
    try {
      const loginPayload = {
        user: userId,
        game_type: "Tic-Tac-Toe",
        Score: score,
        duration: `${minutes}:${seconds}`,
      };

      const response = await ApiServices(
        "post",
        loginPayload,
        ApiEndPoints.tictoe
      );

      if (response.error) {
        console.error("error", response.error);
      } else {
        console.log("response", response);

        // Handle the success response from the API
      }
    } catch (error) {
      console.error("error ", error);

      // Handle any unexpected errors
    }
  };

  return (
    <div className="flex justify-around w-full flex-row sm:flex-col gap-5">

      <div className="flex flex-col bg-deep_purple-50 p-8 rounded-[10px]">
        <div className="flex flex-row">
          {renderSquare(0)}
          {renderSquare(1)}
          {renderSquare(2)}
        </div>
        <div className="flex flex-row">
          {renderSquare(3)}
          {renderSquare(4)}
          {renderSquare(5)}
        </div>
        <div className="flex flex-row">
          {renderSquare(6)}
          {renderSquare(7)}
          {renderSquare(8)}
        </div>
      </div>
      <div className="flex w-2/6 sm:w-full items-center justify-center text-center bg-deep_purple-50 p-7 rounded-[10px] self-stretch justify-self-center">
        <div
          className="d-flex "
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="text-center font-bold text-3xl">{status}</div>
          {winner && (
            <div className="text-center">
              <button
                onClick={handleReset}
                className="mt-4 bg-blue-500 text-white p-2 rounded"
              >
                Restart
              </button>
            </div>
          )}
        </div>
      </div>

      <div
  id="popup-modal"
  tabIndex="-1"
  className={`${
    isGameFinished ? "" : "hidden "
  }overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
>
  <div class="relative p-4 w-full max-w-md max-h-full" style={{ margin: 'auto' }}>
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      {/* <button
        type="button"
        class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        data-modal-hide="popup-modal"
        onClick={handleReset}
      >
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span class="sr-only">Close modal</span>
      </button> */}
      <div class="p-4 md:p-5 text-center">
      {winner === "X" ? (
      <img
      style={{height:'288px'}}
      src='https://i.pinimg.com/736x/94/eb/16/94eb16c8ad235806796d7d734ea2fed6.jpg' alt='Congratulations GIF'/> )
      :
      <img 
      style={{ height:'288px' ,  width:'100%'}}

      src='https://us.123rf.com/450wm/anwarsikumbang/anwarsikumbang1606/anwarsikumbang160600912/59098801-you-lose-comic-speech-bubble-cartoon-game-assets.jpg?ver=6' />
}

        {winner === "X" && (
          <> <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
          Congratulations, you won the game in {minutes} minutes and {seconds} seconds!
        </h3>
        <button
          onClick={handleReset}
          className="mt-4 bg-blue-500 text-white p-2 rounded"
        >Restart</button>
          </>
         
        )}
        {winner === "O"  && (
        
            <> <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            The game is over! Thank you for playing!
          </h3>
          <button
            onClick={handleReset}
            className="mt-4 bg-blue-500 text-white p-2 rounded"
          >Restart</button>
            </>
          
        )}
      </div>
    </div>
  </div>
</div>

    </div>
  );
};

// Helper function to determine the winner
const calculateWinner = (squares) => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  for (const [a, b, c] of lines) {
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }

  return null;
};

export default TicTacGame;

import React from "react";

import { useNavigate } from "react-router-dom";

import { Button, Img, Line, List, Text } from "components";

function Footer() {
  const navigate = useNavigate();

  return (
    <footer className="flex items-center justify-center mt-[21px] md:px-5 w-full">
      <div className="flex flex-col gap-[25px] items-center justify-center w-full">
        <div className="flex flex-col items-center justify-start w-3/4 md:w-full">
          <Img
            className="h-12"
            src="images/img_group163.svg"
            alt="group163"
          />
          <div className="flex flex-col items-center justify-start mt-[30px] w-full">
            <div className="h-[22px]  flex sm:flex-col flex-row gap-[5px] items-center justify-evenly w-full">
              {/* <div className="bg-black-900 h-[5px] sm:mt-0 my-2 rounded-sm w-[5px]"></div> */}

              <Text
                className="common-pointer m-auto text-black-900 text-lg"
                size="txtInterMedium18"

                style={{ textAlign: 'center' }}
              >
                <span onClick={() => navigate("/")} >Home</span> <span className="mx-1" onClick={() => navigate("/about")}>About</span> <span className="mx-1" onClick={() => navigate("/iqtest")}>IQ Test</span > <span className="mx-1" onClick={() => navigate("/contact")}>Contact</span>
                <span className="mx-1" style={{ textWrap: 'nowrap' }} onClick={() => navigate("/privicyPolicy")} >Privacy-policy</span>

                <span className="mx-1" style={{ textWrap: 'nowrap' }} onClick={() => navigate("/TermsCondition")} >Terms & Condition</span>
              </Text>


            </div>
          </div>
          <Text
            className="leading-[27.00px] mt-[33px] text-center text-gray-800_01 text-lg w-full"
            size="txtInterRegular18Gray80001"

          >
            At BrainWise Institute, we're committed to helping you unlock your full cognitive potential. Join us today to embark on a journey of discovery and growth. Experience the power of cognitive enhancement with our innovative platform.
          </Text>
        </div>
        <Button
          className="cursor-pointer w-[100vw]
              sm:font-[16px]
              md:min-w-full text-center text-lg"
          shape="square"
          color="indigo_50_01"
          size="md"
          variant="fill"
        >
          © 2024 BrainWise-Institute. ALL RIGHTS RESERVED.
        </Button>
      </div>
    </footer>
  )
}

export default Footer
// store.js
import { legacy_createStore, combineReducers } from 'redux';
import userReducer from './reducers/user'
import paymentReducer from './reducers/payment';

const rootReducer = combineReducers({
  user: userReducer, // Add your reducers here
  payment: paymentReducer
});

const store = legacy_createStore(rootReducer); // Create Redux store with rootReducer

export default store;

import React, { useEffect } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';

const PaymentCancelScreen = () => {
  const navigate = useNavigate();
  useEffect(()=>{
    setTimeout((()=>{
      navigate('/pricing')
    }),[2000])
  },[])
  return (
    <div className="payment-cancel-screen">
      <div className="cancel-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="72"
          height="72"
          fill="#dc3545"
          className="bi bi-x-circle"
          viewBox="0 0 16 16"
        >
          <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.646 4.646a.5.5 0 0 0-.708.708L7 8.707 4.646 11.06a.5.5 0 1 0 .708.708L8 9.707l2.354 2.353a.5.5 0 0 0 .708-.708L8.707 8l2.353-2.354a.5.5 0 0 0-.708-.708L8 6.293 5.646 3.94a.5.5 0 0 0-.708 0z" />
        </svg>
      </div>
      <div className="cancel-message">Payment canceled.</div>
    </div>
  );
};

export default PaymentCancelScreen;

// actionTypes.js
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const SAVE_USER_DATA = 'SAVE_USER_DATA';

export const SET_PAYMENT_SUCCESS = 'SET_PAYMENT_SUCCESS';
export const SET_PAYMENT_FAILURE = 'SET_PAYMENT_FAILURE';
export const SET_PAYMENT_REQUEST = 'SET_PAYMENT_REQUEST';


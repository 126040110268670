import React ,{useEffect}from "react";

import { useNavigate  } from "react-router-dom";
import { Heading } from "components/Heading";

import { Button, Img, Line, List, Text  } from "components";
import Header from "components/Header";
import Footer from "components/Footer";

const TermsCondition = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <>
            <Header className="bg-gray-200 flex sm:flex-col flex-row sm:gap-5 items-center justify-center md:px-5 shadow-bs w-full" />

      <div className="bg-white-A700 flex flex-col font-inter items-center justify-start mx-auto w-full">
        <div className="bg-gradient1  flex flex-col items-center justify-end p-7 sm:px-5 w-full">
          <div className="flex sm:flex-col flex-row md:gap-10 items-center justify-between max-w-[1175px] mx-auto md:px-5 w-full">
            <div className="flex flex-col gap-2 items-start justify-start">
              <Text
                className="sm:text-[32px] md:text-[38px] text-[42px] text-black-900"
                size="txtInterSemiBold42"
              >
                  Terms & Conditions
              </Text>
            
            </div>
            <Img
              className="h-[243px] md:h-auto object-cover"
              src="images/img_2998315ai1.png"
              alt="2998315aiOne"
            />
          </div>
        </div>
<div  style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" , maxWidth: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop:'12px' , paddingLeft: '1rem', paddingRight: '1rem'}}>
<div className="mx-auto" style={{ maxWidth: '90%' ,  marginTop:'20px' , marginBottom:'20px' }}>
  <h1 className="mt-3" style={{fontWeight:'bold'}}>Terms and conditions</h1>
  <h3 className="mt-3" style={{fontWeight:'bold'}}>Effective date: March 19, 2024</h3>
    <p className="mb-3 mt-2 text-black ">These Terms and Conditions (the “Terms”) apply to your access and use of our website (Brainwise.training) (may be referred to as “Website” or “Platform”), and all related services, features, and content (collectively, the “Services”) provided by UAB Vabankas (may be referred to as “Brainwise.training” “Company” or “We”, “Our”, or “Us”). </p>
    <p className="text-black">If you have any questions about the Terms or our Services, please contact us at support@Brainwise.training  .</p>
    <p className="mt-3 text-black">Please read the Terms carefully. By accessing or using our Services, you acknowledge that you accept and agree to be bound by the Terms. If you do not agree to these Terms, including the governing law and class action waiver in Section 7 (Governing law and disputes), do not use Brainwise.training. Your continued use of Brainwise.training will confirm your acceptance of these Terms.</p>
    <p className="mt-3 text-black"> This is a general audience Platform for ADULTS. We will assume (and by using this Website you warrant that) you have legal capacity to enter into the agreement set out in these Terms (i.e., that you are of sufficient age and mental capacity and are otherwise entitled to be legally bound in contract).</p>
    <h1  className="mt-3" style={{fontWeight:'bold'}}>IF YOU DO NOT AGREE TO THE TERMS, YOU MAY NOT ACCESS OR USE THE SERVICES.</h1>
    <h3 className="mt-3" style={{fontWeight:'bold'}}>1.Changes to the Terms</h3>
    <p className="mt-3 text-black">   We may modify the Terms from time to time. We will notify you by email, through the Services, or by presenting you with a new version of the Terms for you to accept if we make modifications that materially change your rights. Your continued use of the Services after the effective date of an updated version of the Terms will indicate your acceptance of the Terms as modified.
We reserve our right to introduce minor changes to the Terms that do not materially influence your rights (e.g., technical corrections or description of a new feature) without notice.</p>

<h3 className="mt-3" style={{fontWeight:'bold'}}>2.Privacy and security</h3>
    <p className="mt-3 text-black">  Protecting and safeguarding any information You provide through the Platform is important to Us. Information about Our security and privacy practices can be found on Our Privacy Policy (hereinafter referred to as the 'Privacy Policy')..</p>
    <p className="mt-3 text-black">  The Company shall process the User’s Personal Data in accordance with the Privacy Policy. The Personal Data protection and Privacy Policy contains important provisions of the Terms, so it is recommended that the User read them carefully and make sure that all the provisions of the Privacy Policy are understandable and acceptable; available in the Privacy Policy section of the Platform.</p>
    <p className="mt-3 text-black"> By agreeing to these Terms and/or by using the Platform, You are also agreeing to the terms of the Privacy Policy. The Privacy Policy is incorporated into and deemed a part of these Terms. The same rules that apply regarding changes and revisions of these Terms also apply to changes and revisions of the Privacy Policy.</p>
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>3.Important sales terms</h3>
    <p className="mt-3 text-black"> All prices and costs in the United States of America are in US Dollars unless otherwise indicated..</p>
    <p className="mt-3 text-black">  Pricing in other countries may vary and actual charges may be converted to your local currency depending on the country of residence.</p>
    <p className="mt-3 text-black">BY PROCEEDING WITH YOUR PURCHASE AND ORDERING ANY OF THE STAND-ALONE OFFERS YOU ARE ALSO ENROLLING INTO OUR CONTINUOUS SERVICE PROGRAM AND SUBSCRIBING TO THE MEMBERSHIPS/SUBSCRIPTIONS DESCRIBED HEREIN: </p>
    <p className="mt-3 text-black"> If the User does not wish for Services to automatically renew, he may elect to cancel the subscription at least 48 hours before the end of current period, in which case, the Services will be terminated upon expiration of the then current term, unless he manually renews the Services prior to that date.</p>
    <p className="mt-3 text-black">  Continuous Service Program: I understand that unless I tell you otherwise, I will receive uninterrupted service and access; my subscription(s) will be automatically renewed at the end of each subscription term, at the rate(s) then in effect. I authorize you to fulfill my subscription(s) and charge the credit/debit card if provided, or send me a bill if not. I won't be bothered with any renewal notices. I may opt out of the automatic renewal at any time by contacting customer service who’s contact information is as follows: support@Brainwise.training. I understand and acknowledge that in addition to receiving access to my digital “FaceChemistry App” for which I agree to pay the initial fees, I am additionally consenting to be billed $38.00 after three (3) days of the purchase, unless I cancel the service prior to the subsequent billing.</p>
    <p className="mt-3 text-black">All sales are final, and We will not issue refunds for monthly Service subscription fees. By cancelling a monthly subscription, all future charges associated with future months of your subscription will be cancelled. You may notify us of your intent to cancel at any time; your cancellation will become effective at the end of your current monthly billing period. You will not receive a refund; however, your subscription access and/or delivery and accompanying subscriber benefits will continue for the remainder of the current monthly billing period. Service Provider does not refund automatic payments not cancelled in time. </p>
    <p className="mt-3 text-black">
    Our Continuous Service program is designed to avoid interruption in service by guaranteeing that subscribers do not miss any content</p>
</div>
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>4.Disclaimer of warranty and limitation of liability</h3>
    <p className="mt-3 text-black"> YOU UNDERSTAND THE SERVICES ARE FOR ENTERTAINMENT PURPOSES ONLY AND AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK AND ACKNOWLEDGE THAT THE SERVICE AND ANYTHING CONTAINED WITHIN THE SERVICE, INCLUDING, BUT NOT LIMITED TO, CONTENT, SERVICES, DATA ARE PROVIDED “AS IS” AND “AS AVAILABLE,” AND THAT SERVICE PROVIDER TO THE MAXIMUM EXTENT PERMITTED BY LAW MAKES NO WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICE, INCLUDING, BUT NOT LIMITED TO, MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE OR USE OR THOSE ARISING BY STATUTE OR OTHERWISE IN LAW FROM A COURSE OF DEALING OR USAGE OF TRADE OR THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE. THE COMPANY DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THIS PLATFORM OR THE SERVICE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. NO ORAL OR WRITTEN ADVICE OR INFORMATION USER RECEIVES FROM THE DATA PROVIDED BY THE SERVICE PROVIDER WILL CREATE A WARRANTY, AND USER IS NOT ENTITLED TO RELY UPON ANY SUCH ADVICE OR INFORMATION.</p>
    <p className="mt-3 text-black">  You hereby release Us and agree to hold Us harmless from any and all causes of action and claims of any nature resulting from the Services or the Platform, including without limitation any act, omission, opinion, response, advice, suggestion, information, and/or any other content or information accessible through the platform.</p>
    <p className="mt-3 text-black">You understand, agree, and acknowledge that the Platform is provided "as is" without any express or implied warranties of any kind, including but not limited to merchantability, non-infringement, security, and fitness for a particular purpose or accuracy. The use of the platform is at Your own risk. To the fullest extent of the law, We expressly disclaim all warranties of any kind, whether expressed or implied.</p>
    <p className="mt-3 text-black">You understand, agree, and acknowledge that We shall not be liable to You or to any third party for any indirect, incidental, consequential, special, punitive, or exemplary damages.</p>

    <p className="mt-3 text-black"> You understand, agree, and acknowledge that Our aggregate liability for damages arising with respect to this Terms and any and all use of the Services or Platform will not exceed the total amount of money paid by You or on Your behalf through the platform in the 12 months period prior to the date of the claim</p>
    <p className="mt-3 text-black"> If the applicable law does not allow the limitation of liability as set forth above, the limitation will be deemed modified solely to the extent necessary to comply with the applicable law. This limitation of liability shall survive the termination or expiration of these Terms..</p>
 
</div>
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>5.Registration and Eligibility</h3>
    <p className="mt-3 text-black"> To use the Services, You may be required to create an account and to provide certain personal information, which may include your name and e-mail address. You agree that You will supply accurate and complete information to Brainwise.training, and that you will update that information promptly after it changes. Otherwise, the Services may not operate correctly, and we may not be able to contact you with important notices.</p>
    <p className="mt-3 text-black">You may not create the account with a name that you don’t have the right to use, or another person’s name with the intent to impersonate that person.</p>
    <p className="mt-3 text-black">To create an account and access the Services, you must be at least 18 years old and not barred from using the Platform under applicable law. You hereby represent and warrant to the Company that you meet such qualifications.</p>
    <p className="mt-3 text-black">You will not share your account or password with anyone, and you must protect the security of your account and your password. You’re responsible for any activity associated with your account.</p>

    <p className="mt-3 text-black"> The Company has a right to suspend or terminate your account, with or without notice to you, if you breach the Terms.</p>
 
</div>
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>6.Intellectual property</h3>
    <p className="mt-3 text-black"> The Platform, and all of the content it contains, or may contain in the future, including but not limited to text, content, photographs, video, audio and graphics, goods, designs, information, applications, software, music, audio files, articles, directories, guides, photographs as well as trademarks, service marks, trade names, trade dress, copyrights, logos, domain names, code, patents and/or any other form of intellectual property that relates to this Platform or/and Service, are owned by or licensed by the Company or other third parties and are protected from any unauthorized use, copying and dissemination by copyrights, trademarks, service marks, international treaties, and/or other proprietary rights and laws of the Republic of Lithuania and other countries.</p>
    <p className="mt-3 text-black">You agree to protect the proprietary rights of the Company and all others having rights in the Service during and after the Service provision term and to comply with all reasonable written requests made by the Company or its suppliers and licensors of content, equipment, or otherwise (“Suppliers”) to protect their and others’ contractual, statutory, and common law rights in the Service. </p>
    <p className="mt-3 text-black">During the provision of the Services, in accordance with these Terms, the User is granted the non-exclusive right to use the Platform or/and Service, database and the data, and its analyzing solutions provided by the Company only for non-commercial use. You agree to notify the Company in writing promptly upon becoming aware of any unauthorized access or use of the Service by any individual or entity of any claim that the Service infringes upon any copyright, trademark, or other contractual, statutory, or common law rights. All the intellectual property rights shall, as between User and Service Provider, at all times be and remain the sole and exclusive property of the Company. All present and future rights in and title to the Service (including the right to exploit the Service and any portions of the Service over any present or future technology) are reserved to the Company for its exclusive use. Except as specifically permitted by these Terms, you may not copy or make any use of the Service or any portion thereof.</p>
  
 
</div>
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>7.Governing law and disputes</h3>
    <p className="mt-3 text-black"> If You have any complaints regarding the Website, fees, refunds, quality of Services, or anything related to the use of the Platform, You must first contact our customer support team by email (support@Brainwise.training) before taking any action through third parties. Please be noted that by agreeing to these Terms you explicitly agree not the request for any refunds or chargebacks from your bank or credit card operator without priorly contacting us and without giving us a chance to settle any issues that you might have.</p>
    <p className="mt-3 text-black">All complaints or claims provided by you shall be processed within 30 days from receiving. We always put our best efforts into the positive settlement of the complaint or claim. When addressing us with your complaints, you must always identify yourself by the same first name and surname that you have provided to us when purchasing on the Website.</p>
    <p className="mt-3 text-black">The legal relationship between you an Us shall be governed by Lithuanian law, except if your local laws exclude other jurisdictions in consumer related disputes. In such case, the laws of your state will apply.</p>
    <p className="mt-3 text-black">Any claim must be brought in the initiating party’s individual capacity and not as a plaintiff or class member in any class action or other similar proceeding</p>
    <p className="mt-3 text-black">You irrevocably agree that the exclusive venue for any action or proceeding arising out of relating to the Terms or Our relationship with You, regardless of theory, shall be courts located in the Republic of Lithuania. You irrevocably consent to the personal jurisdiction of the aforementioned courts and hereby waive any objection to the exercise of jurisdiction by the aforementioned courts. Nothing in the Terms, including the choice of the Republic of Lithuania, affects Your statutory rights as a consumer to rely on the mandatory consumer protection provisions contained in the law of the country in which You live.</p>


 
</div>
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>8.Notices</h3>
    <p className="mt-3 text-black">We may provide you with notices, including those regarding changes to the Terms, by email or by postings to Service If you have general questions or concerns regarding these Terms, please contact us at support@Brainwise.training. </p>
  


 
</div>
<div>
<h3 className="mt-3" style={{fontWeight:'bold'}}>9.Other notes regarding the Term</h3>
    <p className="mt-3 text-black">The Terms constitutes the entire agreement between You and Us. You confirm that You have not relied upon any promises or representations by Us except as set forth in these Terms. We may freely transfer or assign this Terms or any of its obligations hereunder</p>
    <p className="mt-3 text-black">If any provision of these Terms is held by a court of competent jurisdiction to be illegal, invalid, unenforceable, or otherwise contrary to law, the remaining provisions of these Terms will remain in full force and effect. To clear any doubt, all clauses regarding limitations of liabilities and indemnification shall survive the termination or expiration of these Terms.</p>



 
</div>

<h1 className="text-black text-center mt-3" style={{fontWeight:'bold'}}>I HAVE READ THESE TERMS AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE. </h1>

</div>
</div>
       

      



<Footer/>
     
      </div>
    </>
  );
};

export default TermsCondition;

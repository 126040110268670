import React, { useEffect, useState } from "react";

import { Button, Img, List, Text } from "components";
import Header from "components/Header";
import { useNavigate } from "react-router-dom";
import BraintreeDropIn from "components/BraintreeDropIn/Index";
import Footer from "components/Footer";

const Pricing = () => {
  const navigate = useNavigate();
  const [custumClass, setCustumClass] = useState("hidden");
  const [custumClass1, setCustumClass1] = useState("hidden");
  const [custumClass2, setCustumClass2] = useState("hidden");
  const [selectedDiv, setSelectedDiv] = useState(null);
  const [isAmountSelected, setIsAmountSelected] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);

  const toggleClass = () => {
    setCustumClass((prevClass) => (prevClass === "hidden" ? "" : "hidden"));
  };

  const toggleClass1 = () => {
    setCustumClass1((prevClass) => {
      const newClass = prevClass === "hidden" ? "" : "hidden";
      setTotalAmount(newClass === "" ? 12 : 0);
      return newClass;
    });
  };
  const toggleClass2 = () => {
    setCustumClass2((prevClass) => (prevClass === "hidden" ? "" : "hidden"));
  };

  const handleDivClick = (divId, amount) => {
    setSelectedDiv(divId);
    setTotalAmount(amount);
    setIsAmountSelected(true);

    if (divId === "div1") {
      toggleClass();
    } else if (divId === "div2") {
      toggleClass1();
    } else if (divId === "div3") {
      toggleClass2();
    }
  };
  // console.log(totalAmount);

  const handleDropdownClick = (divId) => {
    setSelectedDiv(selectedDiv === divId ? null : divId);
  };

  const handlePayment = async () => {
    try {
      // if (auth) {
      // Make a POST request to your payment/create API endpoint
      const response = await fetch(
        "https://brainwise.training/api//payment/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers if required
          },
          // Add any request payload if needed
          body: JSON.stringify({
            amount: totalAmount,
            plan: "Gold",
            planExpire: "12/12/2024",
            currency: "USD",
          }),
        }
      );
      // console.log(response)
      // Handle the response (you may need to customize this based on your API)
      const data = await response.json();
      // console.log(data , "data")
      window.open(data.url, "_self");
      // } else {
      //   dispatch({
      //     type: SET_PAYMENT_REQUEST,
      //     payload: {
      //       amount: 500,
      //     },
      //   });
      //   navigate("/login");
      // }
      // setPaymentStatus(data.status); // Set the payment status based on the API response
    } catch (error) {
      console.error("Error creating payment:", error);
      // navigate("/profile");
      // setPaymentStatus("error");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-white-A700 flex flex-col items-center justify-start mx-auto w-full">
        <div className="flex flex-col justify-start w-full">
          <Header className="bg-gray-200 flex sm:flex-col flex-row font-inter sm:gap-5 items-center justify-center md:px-5 shadow-bs1 w-full" />
          <Text
            className=" p-4 sm:text-[34px] md:text-[40px] text-[44px] text-black-900 ml-24 mt-6 sm:m-0"
            size="txtInterSemiBold44"
          >
            Plans & Pricing
          </Text>
          <List
            className="flex flex-col font-inter gap-[30px] items-center max-w-[1177px] mt-[30px] mx-auto md:px-5 w-full"
            orientation="vertical"
          >
            <div className="bg-white-A700 flex flex-wrap flex-row md:gap-5 items-center justify-between p-[21px] sm:px-5 rounded-lg shadow-bs2 w-full">
              <div className="flex flex-row gap-2.5 w-4/6 sm:w-[53%] pl-6 sm:px-0">
                <div
                  className={`border-2 border-deep_purple-800 border-solid  h-[27px] mt-2 rounded-[50%] w-[27px] ${
                    selectedDiv === "div1" ? "bg-blue-500" : ""
                  }`}
                  onClick={() => handleDivClick("div1", 2)}
                ></div>

                <Text
                  className="text-3xl sm:text-[26px] md:text-[28px] mb-3 text-gray-900 tracking-[0.60px]"
                  size="txtInterSemiBold30"
                >
                  <span style={{ fontSize: "18px" }}>Basic Plan </span>
                </Text>
              </div>
              <Text
                className="sm:text-2xl md:text-[32px]  text-[34px] sm:w-[40%]  w-1/4  text-gray-900 text-right tracking-[0.68px]"
                size="txtInterSemiBold34"
              >
                <span className="line-through mr-3 text-red-500"> $ 5</span> $ 2
              </Text>
              <div className="bg-blue_gray-100 flex flex-col items-center justify-start ml-5 md:ml-[0] md:mt-0 my-0.5 p-1.5 rounded w-[4%] sm:w-full">
                <Img
                  className="h-[15px] my-1"
                  src="images/img_arrowdown.svg"
                  onClick={toggleClass}
                  alt="arrowdown"
                />
              </div>
            </div>
            <div
              id="accordion-open-body-1"
              className={custumClass}
              aria-labelledby="accordion-open-heading-1"
              style={{
                borderRadius: "10px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
            >
              <div className="p-5 sm:px-5 sm:py-1 dark:bg-gray-900">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  <span style={{ fontWeight: "bold" }}> Personal Report:</span>
                  Access to a comprehensive analysis of your cognitive abilities
                  based on your performance. This digital report gives you
                  insights into your strengths and areas for improvement.
                </p>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  <span style={{ fontWeight: "bold" }}> Certificate::</span>
                  Receive a digital certificate acknowledging your participation
                  and completion of the initial cognitive assessment.
                </p>
                <p className="text-gray-500 dark:text-gray-400">
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Brain Training Games:
                  </span>
                  Unlimited access to a suite of brain training games designed
                  to enhance your cognitive functions.
                </p>{" "}
              </div>
            </div>

            <div className="bg-white-A700 flex flex-wrap flex-row md:gap-5 items-center justify-between p-[21px] sm:px-5 rounded-lg shadow-bs2 w-full">
              <div className="flex flex-row gap-2.5 w-4/6 sm:w-[53%] pl-6 sm:px-0">
                <div
                  className={`border-2 border-deep_purple-800 border-solid  h-[27px] mt-2 rounded-[50%] w-[27px] ${
                    selectedDiv === "div2" ? "bg-blue-500" : ""
                  }`}
                  onClick={() => handleDivClick("div2", 12)}
                ></div>

                <Text
                  className="text-3xl sm:text-[26px] md:text-[28px] mb-3 text-gray-900 tracking-[0.60px]"
                  size="txtInterSemiBold30"
                >
                  <span style={{ fontSize: "18px" }}>Standard Plan </span>
                </Text>
              </div>
              <Text
                className="sm:text-2xl md:text-[32px]  sm:w-[40%]  w-1/4 text-[34px] text-gray-900 text-right tracking-[0.68px]"
                size="txtInterSemiBold34"
              >
                <span className="line-through mr-3 text-red-500"> $ 25</span>{" "}
                $12
              </Text>
              <div className="bg-blue_gray-100 flex flex-col items-center justify-start ml-5 md:ml-[0] md:mt-0 my-0.5 p-1.5 rounded w-[4%] sm:w-full">
                <Img
                  className="h-[15px] my-1"
                  src="images/img_arrowdown.svg"
                  onClick={toggleClass1}
                  alt="arrowdown"
                />
              </div>
            </div>
            <div
              id="accordion-open-body-1"
              className={custumClass1}
              aria-labelledby="accordion-open-heading-1"
              style={{
                borderRadius: "10px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
            >
              <div className="p-5  dark:bg-gray-900">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Includes everything in the Basic Plan,
                  </span>
                </p>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Handcrafted Personal Report::
                  </span>
                  In addition to the digital report, you'll receive a
                  handcrafted report tailored specifically to your cognitive
                  profile. This report provides a more in-depth analysis and
                  personalized recommendations.
                </p>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Priority Delivery:
                  </span>
                  Your handcrafted personal report and digital certificate will
                  be delivered within 6-24 hours after placing your order,
                  ensuring a swift turnaround time for those eager to start
                  their cognitive enhancement journey.
                </p>
              </div>
            </div>

            <div className="bg-white-A700 flex flex-wrap flex-row md:gap-5 items-center justify-between p-[21px] sm:px-5 rounded-lg shadow-bs2 w-full">
              <div className="flex flex-row gap-2.5 w-4/6 sm:w-[53%] pl-6 sm:px-0">
                <div
                  className={`border-2 border-deep_purple-800 border-solid  h-[27px] mt-2 rounded-[50%] w-[27px] ${
                    selectedDiv === "div3" ? "bg-blue-500" : ""
                  }`}
                  onClick={() => handleDivClick("div3", 25)}
                ></div>

                <Text
                  className="text-2xl sm:text-[26px] md:text-[28px] mb-3 text-gray-900 tracking-[0.60px]"
                  size="txtInterSemiBold30"
                >
                  <span style={{ fontSize: "18px" }}> Premium Plan </span>
                </Text>
              </div>
              <Text
                className="sm:text-2xl md:text-[32px] sm:w-[40%]  w-1/4 text-[34px] text-gray-900 text-right tracking-[0.68px]"
                size="txtInterSemiBold34"
              >
                <span className="line-through mr-3 text-red-500"> $ 50</span>{" "}
                $25
              </Text>
              <div className="bg-blue_gray-100 flex flex-col items-center justify-start ml-5 md:ml-[0] md:mt-0 my-0.5 p-1.5 rounded w-[4%] sm:w-full">
                <Img
                  className="h-[15px] my-1"
                  src="images/img_arrowdown.svg"
                  onClick={toggleClass2}
                  alt="arrowdown"
                />
              </div>
            </div>
            <div
              id="accordion-open-body-1"
              className={custumClass2}
              aria-labelledby="accordion-open-heading-1"
              style={{
                borderRadius: "10px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
            >
              <div className="p-5  dark:bg-gray-900">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Includes everything in the Standard Plan,
                  </span>
                </p>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  <span style={{ fontWeight: "bold" }}>
                    Hyperindividualized Personal Report:{" "}
                  </span>{" "}
                  This top-tier offering includes a hyper-detailed, personalized
                  report that goes beyond the standard analysis. It incorporates
                  a deeper level of customization, reflecting your unique
                  cognitive landscape, preferences, and potential developmental
                  paths.
                </p>
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Expedited Delivery:
                  </span>
                  Like the Standard Plan, expect your hyperindividualized report
                  and digital certificate to be delivered within the swift 6-24
                  hour window after your order.
                </p>
              </div>
            </div>
          </List>
          <div className="flex flex-row font-inter md:gap-10 items-center justify-between max-w-[1177px] mt-[38px] mx-auto md:px-5 w-full">
            <Text
              className="sm:text-[29px] md:text-[31px] text-[33px] text-gray-900 tracking-[0.66px]"
              size="txtInterSemiBold33"
            >
              Total
            </Text>
            <Text
              className="sm:text-3xl md:text-[32px] text-[34px] text-gray-900 text-right tracking-[0.68px]"
              size="txtInterSemiBold34"
            >
              ${totalAmount ? totalAmount : "0"}
            </Text>
          </div>
          <div className=" md:ml-[0] ml-[57px] px-10 sm:px-4">
            <Text
              className=" mt-5  text-[10px]  text-gray-500 w-[92%] sm:w-full"
              size="txtPoppinsRegular21"
            >
              <>
                By opting for any of our plans, you acknowledge and consent to
                the BrainWise Institute's <span className="text-blue-700 common-pointer" onClick={() => navigate("/TermsCondition")} >Terms and Conditions </span> and <span  onClick={() => navigate("/privicyPolicy")} className=" common-pointer text-blue-700" >Privacy
                Policy </span> . Each subscription includes an exclusive three-day trial
                of our Brain Enhancer Premium program, providing unlimited
                access to our cognitive enhancement games, personalized brain
                training modules, and entry into our Brain Training Academy.
                After the trial, this program automatically upgrades to a $
                Thirty-eight monthly subscription. Choose the plan that aligns
                with your cognitive development goals and embark on a journey
                towards enhanced mental agility today with the BrainWise
                Institute.
              </>
            </Text>
            <Text
              className=" mt-[34px]  sm:text-3xl md:text-[32px] text-[34px] text-gray-900 tracking-[0.34px]"
              size="txtInterMedium34"
            >
              Select secure payment method
            </Text>

            <Text
              className=" mt-3 sm:text-2xl md:text-[26px] text-[28px] text-gray-900"
              size="txtInterMedium28"
            >
              Pay with PayPal
            </Text>
            <button disabled={!isAmountSelected} onClick={handlePayment}>
              <Img
                className={`h-[73px] sm:h-auto  mt-[25px] object-cover w-2/3 md:w-full  ${
                  isAmountSelected
                    ? "hover:bg-blue-700"
                    : "cursor-not-allowed opacity-50"
                }`}
                src="images/img_download1.png"
                alt="downloadOne"
              />
            </button>
            <Text
              className="mt-4 sm:text-2xl md:text-[26px] text-[28px] text-gray-900"
              size="txtInterMedium28"
            >
              Pay with Card
            </Text>
            <div className="ml-[-14px]">
              <BraintreeDropIn
                amount={totalAmount}
                isAmountSelected={isAmountSelected}
                className="sm:w-[100%]"
                onPaymentMethodNonce={() => {}}
              />
            </div>
          </div>

          <Footer/>
        </div>
      </div>
    </>
  );
};

export default Pricing;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Img, Line, List, Text } from "components";
import Header from "components/Header";
import "./index.css"; // Import your CSS file for styling

const Checkout3 = () => {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const navigate = useNavigate();
  // creates a paypal order


  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(() => {
    if (success) {
      alert("Payment successful!!");
      console.log("Order successful . Your order id is--", orderID);
    }
  }, [success]);

  return (
    <>
      <div className="bg-white-A700 flex flex-col font-inter items-center justify-start mx-auto w-full">
        <Header className="bg-gray-200 flex sm:flex-col flex-row sm:gap-5 items-center justify-center md:px-5 shadow-bs w-full" />
       
       
        <div className="m-5 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">Pay once, own it forever</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">$10</span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>
                </p>
           
          <button className="paypal-button m-4" >
            <img
              src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png"
              alt="PayPal"
            />
          </button>
  

                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
       
       

        {paymentStatus && (
          <span className={`payment-status ${paymentStatus}`}>
            {paymentStatus === "success"
              ? "Payment successful!"
              : "Payment failed."}
          </span>
        )}
        <footer className="flex items-center justify-center mt-[21px] md:px-5 w-full">
          <div className="flex flex-col gap-[25px] items-center justify-center w-full">
            <div className="flex flex-col items-center justify-start w-[51%] md:w-full">
              {/* <Img
                className="h-12"
                src="images/img_group163.svg"
                alt="group163"
              /> */}
              <div className="flex flex-col items-center justify-start mt-[30px] w-3/5 md:w-full">
                <div className="flex sm:flex-col flex-row gap-[5px] items-center justify-evenly w-full">
                  <div className="bg-black-900 h-[5px] sm:mt-0 my-2 rounded-sm w-[5px]"></div>
                  <div className="h-[22px] relative w-[98%] sm:w-full">
                    <Text
                      className="common-pointer m-auto text-black-900 text-lg"
                      size="txtInterMedium18"
                      onClick={() => navigate("/iqtest")}
                    >
                      Home About IQ Test Contact
                    </Text>
                    <div className="absolute flex flex-row inset-x-[0] items-center justify-between mx-auto top-[32%] w-[56%]">
                      <div className="bg-black-900 h-[5px] rounded-sm w-[5px]"></div>
                      <div className="bg-black-900 h-[5px] rounded-sm w-[5px]"></div>
                      <div className="bg-black-900 h-[5px] rounded-sm w-[5px]"></div>
                    </div>
                  </div>
                </div>
              </div>
              <Text
                className="leading-[27.00px] mt-3.5 text-center text-gray-800_01 text-lg w-full"
                size="txtInterRegular18Gray80001"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur ut massa dui. Aenean posuere turpis sed enim lacinia
                eleifend.
              </Text>
            </div>
            <Button
              className="cursor-pointer w-[100vw] md:min-w-full text-center text-lg"
              shape="square"
              color="indigo_50_01"
              size="md"
              variant="fill"
            >
              © 2023 IQ TESTS. ALL RIGHTS RESERVED.
            </Button>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Checkout3;

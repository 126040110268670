import React, { useState, useEffect } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import Hamburger from "hamburger-react";

import { Button, Text } from "components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Header = (props) => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);

  // const [isDropdownOpen, setDropdownOpen] = useState(false);

  // const handleDropdownToggle = () => {
  //   setDropdownOpen(!isDropdownOpen);
  // };

  const storedUserData = localStorage.getItem("userData");
  const userData = JSON.parse(storedUserData);

  // Access the 'name' property from userData
  const userName = userData ? userData.name : "";
  // console.log(userName.length)

  const LogoutFun = () => {
    window.localStorage.clear();
    navigate("/");
    // window.location.reload();
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const [isMobileView, setMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  return (
    <>
      {isMobileView ? (
        //          <header style={{display:'flex' , justifyContent:'space-between'}}>
        //          <div>      <Hamburger toggled={isOpen} toggle={() => setOpen(!isOpen)} />
        //       </div>

        //          <div   style={{paddingTop:'4px'}}>
        //          {userName ? (
        //                 <div className="cursor-pointer font-inter font-semibold min-w-[117px] sm:ml-[149px] ml-[291px] mr-6 sm:mt-0 my-[13px] rounded-[19px] text-[19px] text-center">
        //                   <div className="flex items-center gap-4  sm:ml-[81px]">
        //                     <div className="relative w-10 h-10 overflow-hidden rounded-full  bg-slate-50">
        //                       <svg
        //                         className="absolute w-12 h-12 text-red-400 -left-1 "
        //                         viewBox="0 0 20 20"
        //                         xmlns="http://www.w3.org/2000/svg"
        //                         fill="slate-500"
        //                       >
        //                         <path
        //                           fill-rule="evenodd"
        //                           d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
        //                           clip-rule="evenodd"
        //                         ></path>
        //                       </svg>
        //                     </div>

        //                     <div id="dropdown"
        //                     style={{    position: "absolute" ,   top: "75px" ,
        //                     display: isDropdownOpen ? 'block' : 'none',
        //                   }}

        //                     class="z-10  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
        //           <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
        //             <li>
        //               <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
        //             </li>

        //             <li>
        //               <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={LogoutFun} >Sign out</a>
        //             </li>
        //           </ul>
        //       </div>

        //                   </div>
        //                 </div>
        //               ) : (
        //                 <Button
        //                   className="cursor-pointer font-inter font-semibold min-w-[117px] sm:ml-[0] ml-[291px]  mr-6 sm:mt-0 my-[13px] rounded-[19px] text-[19px] text-center"
        //                   size="xs"
        //                   variant="gradient"
        //                   color="deep_purple_900_purple_400"
        //                   onClick={() => navigate("/login")}
        //                 >
        //                   Login
        //                 </Button>
        //               )}
        //          </div>

        // //             </header>

        <div style={{ alignItems: "normal" }}>
          <nav class="bg-white border-gray-200 dark:bg-gray-900">
            <div class="max-w-screen-xl flex flex-wrap  justify-between mx-auto p-4">
              <a class="flex items-left space-x-3 rtl:space-x-reverse">
                <img
                  src="/images/img_group173.png"
                  class="h-12"
                  alt="Flowbite Logo"
                  style={{ height: "40px", position: "relative", top: "4px" }}
                />

                <span
                  class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"
                  style={{
                    fontWeight: "bold",
                    fontSize: "19px",
                    textWrap: "nowrap",
                  }}
                >
                  Brainwise institute
                </span>
              </a>
              <div>
                {" "}
                <Hamburger toggled={isOpen} toggle={() => setOpen(!isOpen)} />
              </div>
            </div>
            <div
              id="drawer-example"
              className={`fixed top-0 left-0 z-40 h-screen p-4 flex flex-row justify-between overflow-y-auto transition-transform ${
                isOpen ? "translate-x-0" : "-translate-x-full"
              } bg-white w-screen dark:bg-gray-800`}
              tabIndex="-1"
              aria-labelledby="drawer-label"
            >
              {/* <button type="button"
               onClick={() => setOpen(false)}
               style={{position:'relative'}}
               data-drawer-hide="drawer-example" aria-controls="drawer-example" className="text-gray-400 py-2 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
                 <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                   <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                 </svg>
                 <span className="sr-only">Close menu</span>
               </button> */}
              <div>
                <div className="py-4 ">
                  {" "}
                  <img
                    src="/images/img_group173.png"
                    class="h-12"
                    alt="Flowbite Logo"
                  />
                </div>
                <div className="py-4 " style={{ fontWeight: "bold" }}>
                  {" "}
                  <Link to="/">Home</Link>
                </div>
                <div className="py-4" style={{ fontWeight: "bold" }}>
                  <Link to="/about">About</Link>
                </div>
                <div className="py-4" style={{ fontWeight: "bold" }}>
                  {" "}
                  <Link to="/Iqtest"> IQ Test</Link>
                </div>
                <div className="py-4" style={{ fontWeight: "bold" }}>
                  {" "}
                  <Link to="/contact">Contact</Link>
                </div>

                {userName && userName?.length > 1 ? (
                  <div className="flex flex-col">
                    <div
                      className="py-2"  
                    >
                      {" "}
                      <div >{userName}</div>
                    </div>
                    <div
                      className="py-2"
                     
                    >
                     <a
                              class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                              onClick={() => navigate("/profile")}
                            >
                              Dashboard
                            </a>
                    </div>

                    <div
                      className="py-2"
                      onClick={LogoutFun}
                      style={{ fontWeight: "bold" }}
                    >
                      {" "}
                      logout
                    </div>
                  </div>
                ) : (
                  <div className="py-2" style={{ fontWeight: "bold" }}>
                    {" "}
                    <Link to="/login">Login</Link>
                  </div>
                )}
              </div>
              <div>
                {" "}
                <Hamburger toggled={isOpen} toggle={() => setOpen(!isOpen)} />
              </div>
            </div>
          </nav>
        </div>
      ) : (
        <header className={`${props.className} flex justify-between`}>
          <Text
            className="ml-5 my-3.5 sm:text-[25px] md:text-[27px] text-[29px] text-black-900"
            size="txtInterSemiBold29"
          >
            Brainwise institute{" "}
          </Text>
          <div>
            <Text
              className="common-pointer  my-[21px] text-black-900 text-lg"
              size="txtInterRegular18"
            >
              <span className="mr-8">
                <Link to="/">Home</Link>
              </span>
              <span className="mr-8">
                <Link to="/about">About</Link>
              </span>
              <span className="mr-8">
                <Link to="/Iqtest"> IQ Test</Link>
              </span>
              <span>
                {" "}
                <Link to="/contact">Contact</Link>
              </span>
            </Text>
          </div>
          {userName ? (
            <div className="cursor-pointer font-inter font-semibold min-w-[117px]  mr-6 sm:mt-0 my-[13px] rounded-[19px] text-[19px] text-center">
              <div className="flex items-center gap-4 ">
                <div className="relative w-10 h-10 overflow-hidden rounded-full bg-slate-50">
                  <svg
                    className="absolute w-12 h-12 text-red-400 -left-1 "
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="slate-500"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div className="font-medium dark:text-white">
                  <div onClick={handleDropdownToggle}>{userName}</div>
                </div>

                <div
                  id="dropdown"
                  style={{
                    position: "absolute",
                    top: "75px",
                    display: isDropdownOpen ? "block" : "none",
                  }}
                  class="z-10  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                >
                  <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <a
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => navigate("/profile")}
                      >
                        Dashboard
                      </a>
                    </li>

                    <li>
                      <a
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={LogoutFun}
                      >
                        Sign out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <Button
              className="cursor-pointer font-inter font-semibold min-w-[117px]   mr-6 sm:mt-0 my-[13px] rounded-[19px] text-[19px] text-center"
              size="xs"
              variant="gradient"
              color="deep_purple_900_purple_400"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          )}
        </header>
      )}
    </>
  );
};

Header.defaultProps = {};

export default Header;

import { Button, Img, Text } from "components";
import React, { useState, useEffect } from "react";
// import "./index.css";

const SIZE = 9;
let onFocusRow = null;
let onFocusColl = null;

function generateBoard() {
  // You can implement a more sophisticated Sudoku board generation algorithm here
  // For simplicity, I'll provide a static Sudoku board
  const board = [
    [5, 3, 0, 0, 7, 0, 0, 0, 0],
    [6, 0, 0, 1, 9, 5, 0, 0, 0],
    [0, 9, 8, 0, 0, 0, 0, 6, 0],
    [8, 0, 0, 0, 6, 0, 0, 0, 3],
    [4, 0, 0, 8, 0, 3, 0, 0, 1],
    [7, 0, 0, 0, 2, 0, 0, 0, 6],
    [0, 6, 0, 0, 0, 0, 2, 8, 0],
    [0, 0, 0, 4, 1, 9, 0, 0, 5],
    [0, 0, 0, 0, 8, 0, 0, 7, 9],
  ];
  return board;
}

function SudokuGames() {
  const [board, setBoard] = useState(generateBoard());

  const handleCellChange = (row, col, value) => {
    const newBoard = [...board];
    newBoard[row][col] = value;
    onFocusRow = null;
    onFocusColl = null;
    setBoard(newBoard);
  };

  const handleFocusInput = (row, col) => {
    onFocusRow = row;
    onFocusColl = col;
  };

  const handelVirtualKeyboardChange = (value) => {
    if (onFocusRow !== null && onFocusColl !== null) {
      console.log(
        "onFocusRow",
        onFocusRow,
        "onFocusCol",
        onFocusColl,
        "value",
        value
      );
      const newBoard = [...board];
      newBoard[onFocusRow][onFocusColl] = value;
      setBoard(newBoard);
      onFocusRow = null;
      onFocusColl = null;
    }
  };

  useEffect(() => {
    // You can add additional logic for checking the completion of the Sudoku puzzle
  }, [board]);

  return (
    <>
      <div>
        {/* <h1 className="text-4xl font-bold mb-4">Sudoku Game</h1> */}
        <div className="flex-col gap-2 ">
          {board.map((row, rowIndex) => (
            <div key={rowIndex} className= { `row flex`}>
              {row.map((cell, colIndex) => (
                <Cell
                  key={colIndex}
                  value={cell}
                  onChange={(value) =>
                    handleCellChange(rowIndex, colIndex, value)
                  }
                  handleFocus={() => handleFocusInput(rowIndex, colIndex)}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className=" bg-deep_purple-50 flex md:flex-1 flex-col items-center justify-start p-4 sm:px-5 rounded-[10px] w-[39%] md:w-full">
        <div className="flex flex-col gap-8 items-center justify-start  mt-1 w-[99%] md:w-full">
          <div className="bg-white-A700 flex flex-row gap-[34px] items-center justify-start pr-10 sm:pr-5 rounded-[22px] w-full">
            <Button
              className="cursor-pointer font-medium min-w-[182px] rounded-[22px] sm:text-sm text-[22px] text-center  md:text-xl"
              size="sm"
              variant="gradient"
              color="deep_purple_900_purple_400"
            >
              Normal
            </Button>
            {/* <Text
                      className="text-[22px] text-gray-600 sm:text-lg md:text-xl"
                      size="txtInterMedium22"
                    >
                      Candidate
                    </Text> */}
          </div>
          <div className="gap-3.5 grid grid-cols-3  justify-center min-h-[auto] w-[360px] sm:w-ful">
            <Button
              className="cursor-pointer font-semibold h-[110px] sm:h-12 sm:text-xl md:text-5xl text-[56px] text-center w-full"
              shape="round"
              color="gray_300"
              size="lg"
              variant="fill"
              onClick={() => handelVirtualKeyboardChange(1)}
            >
              1
            </Button>
            <Button
              className="cursor-pointer font-semibold h-[110px] sm:h-12 sm:text-xl md:text-5xl sm:text-[42px] text-[56px] text-center w-full"
              shape="round"
              color="gray_300"
              size="lg"
              variant="fill"
              onClick={() => handelVirtualKeyboardChange(2)}
            >
              2
            </Button>
            <Button
              className="cursor-pointer font-semibold h-[110px] sm:h-12 sm:text-xl md:text-5xl sm:text-[42px] text-[56px] text-center w-full"
              shape="round"
              color="gray_300"
              size="lg"
              variant="fill"
              onClick={() => handelVirtualKeyboardChange(3)}
            >
              3
            </Button>
            <Button
              className="cursor-pointer font-semibold h-[110px] sm:h-12 sm:text-xl md:text-5xl sm:text-[42px] text-[56px] text-center w-full"
              shape="round"
              color="gray_300"
              size="lg"
              variant="fill"
              onClick={() => handelVirtualKeyboardChange(4)}
            >
              4
            </Button>
            <Button
              className="cursor-pointer font-semibold h-[110px] sm:h-12 sm:text-xl md:text-5xl sm:text-[42px] text-[56px] text-center w-full"
              shape="round"
              color="gray_300"
              size="lg"
              variant="fill"
              onClick={() => handelVirtualKeyboardChange(5)}
            >
              5
            </Button>
            <Button
              className="cursor-pointer font-semibold h-[110px] sm:h-12 sm:text-xl md:text-5xl sm:text-[42px] text-[56px] text-center w-full"
              shape="round"
              color="gray_300"
              size="lg"
              variant="fill"
              onClick={() => handelVirtualKeyboardChange(6)}
            >
              6
            </Button>
            <Button
              className="cursor-pointer font-semibold h-[110px] sm:h-12 sm:text-xl md:text-5xl sm:text-[42px] text-[56px] text-center w-full"
              shape="round"
              color="gray_300"
              size="lg"
              variant="fill"
              onClick={() => handelVirtualKeyboardChange(7)}
            >
              7
            </Button>
            <Button
              className="cursor-pointer font-semibold h-[110px] sm:h-12 sm:text-xl md:text-5xl sm:text-[42px] text-[56px] text-center w-full"
              shape="round"
              color="gray_300"
              size="lg"
              variant="fill"
              onClick={() => handelVirtualKeyboardChange(8)}
            >
              8
            </Button>

            <Button
              className="cursor-pointer font-semibold h-[110px] sm:h-12 sm:text-xl md:text-5xl sm:text-[42px] text-[56px] text-center w-full"
              shape="round"
              color="gray_300"
              size="lg"
              variant="fill"
              onClick={() => handelVirtualKeyboardChange(9)}
            >
              9
            </Button>
          </div>

          <div
            className="bg-cover bg-no-repeat flex flex-col h-14 items-center justify-start p-[11px] w-[62%] md:w-full"
            style={{ backgroundImage: "url('images/img_group43.png')" }}
          >
            <Button
              className="cursor-pointer font-medium min-w-[182px] rounded-[22px] text-[22px] text-center sm:text-lg md:text-xl"
              size="sm"
              variant="gradient"
              color="deep_purple_900_purple_400"
            >
              <Img
                className="h-[20px] w-[20px] m-1 ml-auto mr-auto"
                src="images/img_cross1.svg"
                alt="crossOne"
              />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

const Cell = ({ value, onChange, handleFocus }) => {
  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10) || 0;
    onChange(newValue);
  };

  return (
    <input
      type="text"
      maxLength="1"
      value={value === 0 ? "" : value}
      onChange={handleChange}
      onFocus={handleFocus}
      className="w-20 h-16 sm:w-10 sm:h-8 sm:text-sm text-center font-semibold text-4xl focus:outline-none focus:border-2 focus:border-blue-900"
    />
  );
};

export default SudokuGames;

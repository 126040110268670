import React, { useState, useEffect } from "react";
import { ApiServices } from "NetworkCall";
import {ApiEndPoints} from "NetworkCall";
const shuffleArray = (array) => {
  const shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const generateCards = () => {
  const cardValues = ["A", "B", "C", "D", "E", "F", "G", "H"];
  const doubledCards = [...cardValues, ...cardValues];
  return shuffleArray(doubledCards);
};

const MemoryCardGame = () => {
  const [cards, setCards] = useState(generateCards());
  const [flippedIndices, setFlippedIndices] = useState([]);
  const [matchedPairs, setMatchedPairs] = useState([]);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [timer, setTimer] = useState(null); // New state for the timer
  const [showMsg , setShowmsg] = useState(false);
  const storedUserData = localStorage.getItem("userData");
  const userName = storedUserData ? JSON.parse(storedUserData).name : "";
  const userId = storedUserData ? JSON.parse(storedUserData)._id : "";

  const handleCardClick = (index) => {
    if (flippedIndices.length === 2) {
      return;
    }
    let newSetFlipped = [...flippedIndices, index];
    setFlippedIndices(newSetFlipped);

    if (flippedIndices.length === 1) {
      setTimeout(() => checkForMatch(newSetFlipped), 1000);
    }
  };

  const checkForMatch = (newSetFlipped) => {
    const [firstIndex, secondIndex] = newSetFlipped;
    if (cards[firstIndex] === cards[secondIndex]) {
      setMatchedPairs([...matchedPairs, cards[firstIndex]]);
    }
    setFlippedIndices([]);
  };

  // useEffect(() => {
  //   if (matchedPairs.length === cards.length / 2) {
  //     clearInterval(timer); 
  //     setShowmsg(true)
  //   //  alert(`Congratulations! You have matched all pairs. Total time: ${minutes} minutes and ${seconds} seconds.`);
  //    // handleFormSubmit(minutes * 60 + seconds); 
  //   }
  // }, [matchedPairs, cards.length, minutes, seconds, timer]);


  useEffect(() => {
    if (matchedPairs.length === cards.length / 2) {
      clearInterval(timer);
      setShowmsg(true);
    }
  }, [matchedPairs, cards.length, timer]);
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds + 1) % 60);
      setMinutes((prevMinutes) => Math.floor((prevMinutes + (seconds + 1) / 60) % 60));
    }, 1000);

    setTimer(intervalId);

    return () => {
      clearInterval(intervalId);
    };
  }, [seconds]);




  const handleFormSubmit = async (score) => {
    try {
      const loginPayload = {
        user: userId,
        game_type: "Memory Card",
        Score: 1,
        duration: `${minutes}:${seconds}`,
      };

      const response = await ApiServices(
        "post",
        loginPayload,
        ApiEndPoints.tictoe
      );

   console.log(loginPayload)

      if (response.error) {
        console.error("error", response.error);
      } else {
        console.log("response", response);
        setShowmsg(false)
        setCards(generateCards());
        setFlippedIndices([]);
        setMatchedPairs([]);
        setSeconds(0);
        setMinutes(0);
        setTimer(null);
        
        // Handle the success response from the API
      }
    } catch (error) {
      console.error("error ", error);

      // Handle any unexpected errors
    }
  };
  

  return (
    <div className="w-full mx-5">
        <div class="relative p-4 w-full max-w-md max-h-full" style={{ margin: 'auto' , position:'relative'  , top:"-356px" }}>
             {showMsg &&
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

          
          <div class="p-4 md:p-5 text-center">
          {showMsg  && (
          <img
          style={{height:'288px'}}
          src='https://i.pinimg.com/736x/94/eb/16/94eb16c8ad235806796d7d734ea2fed6.jpg' alt='Congratulations GIF'/> )
          

    }
    
            {showMsg && (
              <> <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Congratulations, you won the game in {minutes} minutes and {seconds} seconds!
            </h3>
            <button
              onClick={handleFormSubmit}
              className="mt-4 bg-blue-500 text-white p-2 rounded"
            >Restart</button>
              </>
             
            )}
         
          </div>
        </div>
}
      </div>
      
      <div className="grid grid-cols-4 gap-2 justify-center p-4  sm:grid-cols-2">
        {cards.map((card, index) => (
          <div
            key={index}
            className={`h-28 text-center  text-8xl p-3  font-semibold  cursor-pointer ${
              flippedIndices.includes(index) || matchedPairs.includes(card)
                ? "bg-deep_purple-50"
                : "bg-gradient-to-r from-blue-600 to-purple-600 "
            }`}
            onClick={() => handleCardClick(index)}
          >
            {flippedIndices.includes(index) || matchedPairs.includes(card)
              ? card
              : " "}
          </div>
        ))}
      </div>
      
      
    </div>
  );
};

export default MemoryCardGame;

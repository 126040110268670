const APPConfig = {
   //  API_URL: "https://brainwise.training/api/" , 
   API_URL: "https://brainwise.training/api/" , 
  
  };


  const ApiEndPoints = {
    login: `${APPConfig.API_URL}/users/login`,
    register: `${APPConfig.API_URL}/users/signup`,
    tictoe : `${APPConfig.API_URL}/users/ticToe `,
    
  }
  export default ApiEndPoints
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useNavigate } from "react-router-dom";
import "./index.css";
import { Button, Img, Line, List, Text } from "components";
import Header from "components/Header";
import { ApiEndPoints } from "NetworkCall";
import { ApiServices } from "NetworkCall";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_SUCCESS } from "../../redux/actionsTypes";
const LoginPage = () => {
  const navigate = useNavigate();
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const dispatch = useDispatch();

  const [signupName, setSignupName] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const paymentState = useSelector((state) => state.payment);
  console.log("paymentState", paymentState);
  const handleLoginEmailChange = (e) => {
    const value = e.target.value;
    console.log("Login Email changed", value);
    setLoginEmail(value);
  };

  const handleLoginPasswordChange = (e) => {
    const value = e.target.value;
    console.log("Login Password changed", value);
    setLoginPassword(value);
  };

  const handleSignupNameChange = (e) => {
    const value = e.target.value;
    console.log("Signup Name changed", value);
    setSignupName(value);
  };

  const handleSignupEmailChange = (e) => {
    const value = e.target.value;
    console.log("Signup Email changed", value);
    setSignupEmail(value);
  };

  const handleSignupPasswordChange = (e) => {
    const value = e.target.value;
    console.log("Signup Password changed", value);
    setSignupPassword(value);
  };

  const handleLoginFormSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const loginPayload = {
        email: loginEmail,
        password: loginPassword,
      };
  
      console.log("loginPayload", loginPayload);
  
      const response = await fetch(ApiEndPoints.login, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginPayload),
      });
  
      if (!response.ok) {
        if (response.status === 401 || response.status === 404) {
          console.error("Login failed", response.status);
          toast("Login failed");
        } else {
          console.error("Login failed", response.error);
          toast("Login failed");
        }
        return;
      }
  
      const responseData = await response.json();
  
      console.log("", responseData);
      toast("Login successful");
  
      localStorage.setItem("userData", JSON.stringify(responseData));
  
      if (responseData.role === "admin") {
        navigate("/admin");
        localStorage.setItem("loginToken", responseData.token);
      } else {
        navigate("/profile");
        localStorage.setItem("loginToken", responseData.token);
      }
    } catch (error) {
      console.error("Login failed", error);
      toast("Login failed");
      // Handle unexpected errors during login
    }
  };
  
  
  

  const signUpHandler = () => {
    const container = document.querySelector(".container");
    container.classList.add("sign-up-mode");
  };

  const signInHandler = () => {
    const container = document.querySelector(".container");
    container.classList.remove("sign-up-mode");
  };

  return (
    <div style={{ overflowY: "hidden" }}>
      <Header className="bg-gray-200 flex sm:flex-col flex-row sm:gap-5 items-center justify-center md:px-5 shadow-bs w-full" />

      <div className="bg-white-A700 flex flex-col font-inter items-center justify-start mx-auto w-full">
        <div className="container">
          <div className="forms-container">
            <div className="signin-signup">
              <form className="sign-in-form">
                <h2 className="title">Sign in</h2>
                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <input
                    type="text"
                    placeholder="Username"
                    onChange={handleLoginEmailChange}
                  />
                </div>
                <div className="input-field">
                  <i className="fas fa-lock"></i>
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={handleLoginPasswordChange}
                  />
                </div>
                <button
                  type="submit"
                  className="btn"
                  onClick={handleLoginFormSubmit}
                  style={{ backgroundColor: "#5995fd", color: "white" }}
                >
                  Login
                </button>

                {/* <p className="social-text">Or Sign in with social platforms</p>
                <div className="social-media">
                  <a href="#" className="social-icon">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#" className="social-icon">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#" className="social-icon">
                    <i className="fab fa-google"></i>
                  </a>
                  <a href="#" className="social-icon">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div> */}
              </form>

              {/* <form action="#" className="sign-up-form">
                <h2 className="title">Sign up</h2>
                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <input
                    type="text"
                    placeholder="Username"
                    onChange={handleSignupNameChange}
                  />
                </div>
                <div className="input-field">
                  <i className="fas fa-envelope"></i>
                  <input
                    type="email"
                    placeholder="Email"
                    onChange={handleSignupEmailChange}
                  />
                </div>
                <div className="input-field">
                  <i className="fas fa-lock"></i>
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={handleSignupPasswordChange}
                  />
                </div>
                <button
                  type="submit"
                  className="btn"
                  value="Sign up"
                  onClick={handleSignupFormSubmit}
                >
                  Sign up
                </button>
                <input />
                <p className="social-text">Or Sign up with social platforms</p>
                <div className="social-media">
                  <a href="#" className="social-icon">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#" className="social-icon">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#" className="social-icon">
                    <i className="fab fa-google"></i>
                  </a>
                  <a href="#" className="social-icon">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </form> */}
            </div>
          </div>

          <div className="panels-container">
            <div className="panel left-panel">
              {/* <div className="content">
                <h3>New here ?</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Debitis, ex ratione. Aliquid!
                </p>
                <button
                  className="btn transparent"
                  id="sign-up-btn"
                  onClick={signUpHandler}
                >
                  Sign up
                </button>
              </div> */}
              <img
                src="images/img_group173.png"
                className="image"
                alt=""
                style={{ width: "71%" }}
              />
            </div>
            {/* <div className="panel right-panel">
              <div className="content">
                <h3>One of us ?</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Nostrum laboriosam ad deleniti.
                </p>
                <button
                  className="common-pointer cursor-pointer font-inter font-semibold min-w-[177px] mt-[19px] text-[22px] text-center sm:text-lg md:text-xl"
                  id="sign-in-btn"
                  onClick={signInHandler}
                >
                  Sign in
                </button>
              </div>
              <img src="images/img_2895241ai.svg" className="image" alt="" />
            </div> */}
          </div>
        </div>

        <footer className="flex items-center justify-center mt-[21px] md:px-5 w-full">
          <div className="flex flex-col gap-[25px] items-center justify-center w-full">
            {/* <div className="flex flex-col items-center justify-start w-[51%] md:w-full">
              <Img
                className="h-12"
                src="images/img_group163.svg"
                alt="group163"
              />
              <div className="flex flex-col items-center justify-start mt-[30px] w-3/5 md:w-full">
                <div className="flex sm:flex-col flex-row gap-[5px] items-center justify-evenly w-full">
                  <div className="bg-black-900 h-[5px] sm:mt-0 my-2 rounded-sm w-[5px]"></div>
                  
                </div>
              </div>
           
            </div> */}
            {/* <Button
              className="cursor-pointer w-[100vw] md:min-w-full text-center text-lg"
              shape="square"
              color="indigo_50_01"
              size="md"
              variant="fill"
            >
              © 2023 IQ TESTS. ALL RIGHTS RESERVED.
            </Button> */}
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LoginPage;

import React, { useEffect, useState } from 'react'
import './admin.css'
import './index.css'
import { Text } from "components";
import { useNavigate } from "react-router-dom";
import Hamburger from "hamburger-react";

const AdminHome = () => {
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);
    const [isMobileView, setMobileView] = useState(window.innerWidth <= 768);
    const [showTable, setShowTable] = useState(true);
    const [paymentTab, setPaymentTab] = useState(true);
    const [activeTab, setActiveTab] = useState('dashboard');
    const [users, setUsers] = useState([]);
    const [adminData, setAdminData] = useState({ todayPayment: 0, totalPayment: 0 });
    const [payments, setPayments] = useState([]);
    const storedUserData = localStorage.getItem('userData');

    const userData = JSON.parse(storedUserData);
    // Access the 'name' property from userData
    const userName = userData ? userData.name : '';

    console.log(userName);
    useEffect(() => {
        getUsersList();
        getPaymentList();
    }, [])


    const getUsersList = async () => {
        const token =await  localStorage.getItem('loginToken');
        const response = await fetch("https://brainwise.training/api//users/allUsers", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
            const data = await response.json();
            setUsers(data)
        }
    }

    const getPaymentList = async () => {
        const token = await localStorage.getItem('loginToken');
        const response = await fetch("https://brainwise.training/api//payment/allPaymentDeteils", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
            const data = await response.json();
            setPayments(data)
            calculateAdminPayment(data)
        }
    }

    const LogoutFun = () => {
        navigate('/');
        window.localStorage.clear();
        window.location.reload();
    }

    const ShowUsersTable = () => {
        setShowTable(false);
        setPaymentTab(true);
        setActiveTab('users');
    }

    const checkClass = () => {
        setShowTable(true);
        setActiveTab('dashboard');
    }

    const checkPaymentClass = () => {
        setShowTable(true);
        setPaymentTab(false);
        setActiveTab('payment');
    }

    const handelMobileOption = (handelFunction) => {
        setOpen(!isOpen)
        handelFunction();
    }

    const calculateAdminPayment = (paymentsList) => {
        let todayPayment = 0, totalPayment = 0;
        let today = new Date().toDateString();
        console.log("paymentsList", paymentsList)
        paymentsList.map((user) => {
            // Convert the date string to a Date object
            let date = new Date(user.create_time).toDateString();
            // Check if the date matches today's date
            if (date === today) {
                todayPayment += Number(user.amount)
            } else {
                totalPayment += Number(user.amount)
            }
        })
        totalPayment += todayPayment
        console.log("totalPayment ", totalPayment)
        console.log("todayPayment ", todayPayment)
        setAdminData({ totalPayment, todayPayment })
    }

    useEffect(() => {
        const handleResize = () => {
            setMobileView(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (

        <>
            <div className="bg-white-A700 flex flex-col font-inter items-center justify-start mx-auto w-full">
                <div className='w-full' >
                    {isMobileView ? (
                        <div style={{ alignItems: "normal" }}>
                            <nav class="bg-white border-gray-200 dark:bg-gray-900">
                                <div class="max-w-screen-xl flex flex-wrap  justify-between mx-auto p-4">
                                    <a class="flex items-left space-x-3 rtl:space-x-reverse">
                                        <img
                                            src="/images/img_group173.png"
                                            class="h-12"
                                            alt="Flowbite Logo"
                                            style={{ height: "40px", position: "relative", top: "4px" }}
                                        />

                                        <span
                                            class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "19px",
                                                textWrap: "nowrap",
                                            }}
                                        >
                                            Brainwise institute
                                        </span>
                                    </a>
                                    <div>
                                        <Hamburger toggled={isOpen} toggle={() => setOpen(!isOpen)} />
                                    </div>
                                </div>

                                <div
                                    id="drawer-example"
                                    className={`fixed top-0 left-0 z-40 h-screen p-4 flex flex-row justify-between overflow-y-auto transition-transform ${isOpen ? "translate-x-0" : "-translate-x-full"
                                        } bg-white w-screen dark:bg-gray-800`}
                                    tabIndex="-1"
                                    aria-labelledby="drawer-label"
                                >
                                    <div>
                                        <div className="py-4 ">
                                            {" "}
                                            <img
                                                src="/images/img_group173.png"
                                                class="h-12"
                                                alt="Flowbite Logo"
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="py-2">
                                                <div onClick={() => handelMobileOption(checkClass)} >Dashboard</div>
                                            </div>
                                            <div onClick={() => handelMobileOption(ShowUsersTable)} className="py-2">
                                                <div >Users</div>
                                            </div>
                                            <div onClick={() => handelMobileOption(checkPaymentClass)} className="py-2">
                                                <div>Payments</div>
                                            </div>

                                            <div className="py-2">
                                                <div>{userName}</div>
                                            </div>

                                            <div
                                                className="py-2"
                                                onClick={LogoutFun}
                                                style={{ fontWeight: "bold" }}
                                            >
                                                logout
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <Hamburger toggled={isOpen} toggle={() => setOpen(!isOpen)} />
                                    </div>
                                </div>
                            </nav>
                        </div>
                    ) : (
                        <header className="bg-gray-200 flex sm:flex-col flex-row sm:gap-5 items-center  justify-center md:px-5 shadow-bs w-full flex justify-between" >
                            <Text
                                className="ml-5 my-3.5 sm:text-[25px] md:text-[27px] text-[29px] text-black-900"
                                size="txtInterSemiBold29"
                            >
                                Brainwise institute{" "}
                            </Text>

                            <div className="cursor-pointer font-inter font-semibold min-w-[117px]  mr-6 sm:mt-0 my-[13px] rounded-[19px] text-[19px] text-center">
                                <div className="flex items-center gap-4 ">
                                    <div className="relative w-10 h-10 overflow-hidden rounded-full bg-slate-50">
                                        <svg
                                            className="absolute w-12 h-12 text-red-400 -left-1 "
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="slate-500"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </div>
                                    <div className="font-medium dark:text-white">
                                        <div>{userName}</div>

                                    </div>
                                    <div
                                        className="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 rounded-full text-sm px-5 py-2.5 text-center"
                                        onClick={LogoutFun}
                                    >
                                        {" "}
                                        Logout
                                    </div>
                                </div>
                            </div>
                        </header>
                    )}
                </div>;
                {/* new desging */}
                <div className="dark:text-slate-400 w-full " >
                    <div className="flex ct1ew h-screen" style={{ overflowY: 'scroll' }}>
                        {!isMobileView &&
                            <div className="bg-slate-900 w-1/6"  >
                                <div className='' >
                                    <div className="flex">
                                        <svg width="100" height="100" viewBox="0 0 32 32" className='mx-auto my-5'>
                                            <defs>
                                                <linearGradient x1="28.538%" y1="20.229%" x2="100%" y2="108.156%" id="logo-a">
                                                    <stop stop-color="#A5B4FC" stop-opacity="0" offset="0%"></stop>
                                                    <stop stop-color="#A5B4FC" offset="100%"></stop>
                                                </linearGradient>
                                                <linearGradient x1="88.638%" y1="29.267%" x2="22.42%" y2="100%" id="logo-b">
                                                    <stop stop-color="#38BDF8" stop-opacity="0" offset="0%"></stop>
                                                    <stop stop-color="#38BDF8" offset="100%"></stop>
                                                </linearGradient>
                                            </defs>
                                            <rect fill="#6366F1" width="32" height="32" rx="16"></rect>
                                            <path d="M18.277.16C26.035 1.267 32 7.938 32 16c0 8.837-7.163 16-16 16a15.937 15.937 0 01-10.426-3.863L18.277.161z" fill="#4F46E5"></path>
                                            <path d="M7.404 2.503l18.339 26.19A15.93 15.93 0 0116 32C7.163 32 0 24.837 0 16 0 10.327 2.952 5.344 7.404 2.503z" fill="url(#logo-a)"></path>
                                            <path d="M2.223 24.14L29.777 7.86A15.926 15.926 0 0132 16c0 8.837-7.163 16-16 16-5.864 0-10.991-3.154-13.777-7.86z" fill="url(#logo-b)"></path>
                                        </svg>
                                    </div>
                                    <div className="c6kpa">
                                        <div>
                                            <ul className="cabqp">
                                                <li className={`rounded-sm ${activeTab === 'dashboard' ? 'containerDisplay' : ''} cyf82 cp8im cjm6w cfnh0`}>
                                                    <div className="flex items-center cmgwo" onClick={checkClass}>
                                                        <div className="flex items-center">
                                                            <svg className="c7n6y ceob2 chcsl" viewBox="0 0 24 24">
                                                                <path className="text-indigo-500 c3wll" d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"></path>
                                                                <path className="text-indigo-600 c3wll" d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"></path>
                                                                <path className="text-indigo-200 c3wll" d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"></path>
                                                            </svg>
                                                            <div className={`mx-6 text-slate-50 ${activeTab === 'dashboard' ? 'font-bold' : ''}`}>Dashboard</div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className={`rounded-sm ${activeTab === 'users' ? 'containerDisplay' : ''} cyf82 cp8im cjm6w cfnh0`}>
                                                    <div className="flex items-center cmgwo">
                                                        <div className="flex items-center">
                                                            <svg className="c7n6y ceob2 chcsl" viewBox="0 0 24 24">
                                                                <path className="c6w4h c3wll" d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"></path>
                                                                <path className="czgoy c3wll" d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"></path>
                                                            </svg>

                                                            <div className="mx-6 text-slate-50" onClick={ShowUsersTable} >Users</div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className={`rounded-sm ${activeTab === 'payment' ? 'containerDisplay' : ''} cyf82 cp8im cjm6w cfnh0`}>
                                                    <div className="flex items-center cmgwo">
                                                        <div className="flex items-center">
                                                            <svg className="c7n6y ceob2 chcsl" viewBox="0 0 24 24">
                                                                <path className="czgoy c3wll" d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"></path>
                                                                <path className="ccazs c3wll" d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"></path>
                                                                <path className="c6w4h c3wll" d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"></path>
                                                            </svg>
                                                            <div className="mx-6 text-slate-50" onClick={checkPaymentClass} >Payments </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* 
                                <div className=" justify-end cs8uq c9pf1 c1481 czx8a">
                                    <div className="cjm6w cfnh0">
                                        <button >
                                            <span className="cbl3h">Expand / collapse sidebar</span>
                                            <svg className="cazit c3wll ceob2 chcsl" viewBox="0 0 24 24">
                                                <path className="czgoy" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"></path>
                                                <path className="c6w4h" d="M3 23H1V1h2z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div> */}

                                </div>
                            </div>
                        }
                        <div className="flex  w-5/6 c8gbp cqd18 c4ijw chmlm cy6kd">
                            <div className="cewy4 clpyc cj3hv cofxq c3ff8 c9r0z c0ycj">
                                {/* display message */}
                                <div className="rounded-sm clgz1 ct1ew clq63 c4ijw c917q ce97l c5mbg">
                                    <div className="c4ijw">
                                        <h1 className="text-slate-800 dark:text-slate-100 font-bold cy709 cjefr ci4cg">Good afternoon, Admin. 👋</h1>
                                        <p className="c6jet">Here is what’s happening with your projects today:</p>
                                    </div>

                                </div>
                                {showTable && activeTab !== 'payment' &&
                                    <div className="flex flex-row  md:flex-col justify-stretch gap-5 mb-10" >
                                        <div className="flex grow pb-6 bg-white dark:bg-slate-800 rounded-sm border border-slate-200 dark:border-slate-700 cugyv c04fd c4osb cetne chmlm">
                                            <div className="cx95x cc5dk ">
                                                <header className="flex cmgwo cxbmt c7j98">
                                                    <img src="images/icon-01.svg" width="32" height="32" alt="Icon 01" />

                                                    <div className="inline-flex c4ijw" x-data="{ open: false }">
                                                    </div>
                                                </header>
                                                <h2 className="text-slate-800 dark:text-slate-100 cqosy cs31h c7j98">Today Payments</h2>
                                                <div className="flex cxbmt">
                                                    <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2">${adminData.todayPayment}</div>
                                                    <div className="text-sm rounded-full ck3g6 cqosy ceqwg c6er6">+</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex grow pb-6 bg-white dark:bg-slate-800 rounded-sm border border-slate-200 dark:border-slate-700 cugyv c04fd c4osb cetne chmlm">
                                            <div className="cx95x cc5dk">
                                                <header className="flex cmgwo cxbmt c7j98">
                                                    <img src="images/icon-02.svg" width="32" height="32" alt="Icon 02" />
                                                </header>
                                                <h2 className="text-slate-800 dark:text-slate-100 cqosy cs31h c7j98">Total Users</h2>
                                                {/* <div className="ciz4v czgoy cqosy cw3n3 c0qeg ci4cg">Sales</div> */}
                                                <div className="flex cxbmt">
                                                    <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2">{users.length}</div>
                                                    <div className="text-sm rounded-full cqosy ctnuc ceqwg c6er6">+</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex grow pb-6 bg-white dark:bg-slate-800 rounded-sm border border-slate-200 dark:border-slate-700 cugyv c04fd c4osb cetne chmlm">
                                            <div className="cx95x cc5dk">
                                                <header className="flex cmgwo cxbmt c7j98">
                                                    <img src="images/icon-03.svg" width="32" height="32" alt="Icon 03" />
                                                </header>
                                                <h2 className="text-slate-800 dark:text-slate-100 cqosy cs31h c7j98">Total Payments</h2>
                                                {/* <div className="ciz4v czgoy cqosy cw3n3 c0qeg ci4cg">Sales</div> */}
                                                <div className="flex cxbmt">
                                                    <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2">${adminData.totalPayment}</div>
                                                    <div className="text-sm rounded-full ck3g6 cqosy ceqwg c6er6">+</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* user table  section*/}
                                {paymentTab ?
                                    <div className="bg-white dark:bg-slate-800 rounded-sm border border-slate-200 dark:border-slate-700 cvgln c4osb cetne">
                                        <header className="dark:border-slate-700 cjw7x cz4zt cx95x ctysv">
                                            <h2 className="text-slate-800 dark:text-slate-100 cqosy"> New Users</h2>
                                        </header>
                                        <div className="cmue4">
                                            {/* user table */}
                                            <div className="cjrun">
                                                <table className="ch1ih c07b2 c3ff8">
                                                    <thead className="rounded-sm ciz4v c1vyl ce4zx czgoy cn1je cw3n3 c0qeg">
                                                        <tr>
                                                            <th className="cnk1n">
                                                                <div className="cqosy cojuf">Name</div>
                                                            </th>
                                                            <th className="cnk1n">
                                                                <div className="cqosy cob4g">Email</div>
                                                            </th>
                                                            <th className="cnk1n">
                                                                <div className="cqosy cob4g">User plan</div>
                                                            </th>
                                                            <th className="cnk1n">
                                                                <div className="cqosy cob4g">Plan expire</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-sm ci5zp cz8yz cw92y cxw08">
                                                        {users.map((user, index) => (

                                                            <tr key={index}>
                                                                <td className="cnk1n">
                                                                    <div className="flex items-center">
                                                                        {/* <svg className="mr-2 c7n6y c152z" width="36" height="36" viewBox="0 0 36 36">
                                                                        <circle fill="#24292E" cx="18" cy="18" r="18"></circle>
                                                                        <path d="M18 10.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V24c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" fill="#FFF"></path>
                                                                    </svg> */}
                                                                        <div className="text-slate-800 dark:text-slate-100">{user.name}</div>
                                                                    </div>
                                                                </td>
                                                                <td className="cnk1n">
                                                                    <div className="cob4g">{user.email}</div>
                                                                </td>
                                                                <td className="cnk1n">
                                                                    <div className="clfqm cob4g">{user.user_plan}</div>
                                                                </td>
                                                                <td className="cnk1n">
                                                                    <div className="cob4g">{user.plan_expire}</div>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                }
                                {showTable ?
                                    <div className="bg-white mt-5 dark:bg-slate-800 rounded-sm border border-slate-200 dark:border-slate-700 cvgln c4osb cetne">
                                        <header className="dark:border-slate-700 cjw7x cz4zt cx95x ctysv">
                                            <h2 className="text-slate-800 dark:text-slate-100 cqosy"> New payment</h2>
                                        </header>
                                        <div className="cmue4">
                                            {/* user table */}
                                            <div className="cjrun">
                                                <table className="ch1ih c07b2 c3ff8">
                                                    <thead className="rounded-sm ciz4v c1vyl ce4zx czgoy cn1je cw3n3 c0qeg">
                                                        <tr>
                                                            <th className="cnk1n">
                                                                <div className="cqosy cojuf">PaymentId</div>
                                                            </th>
                                                            <th className="cnk1n">
                                                                <div className="cqosy cob4g">Email</div>
                                                            </th>
                                                            <th className="cnk1n">
                                                                <div className="cqosy cob4g">Amount</div>
                                                            </th>
                                                            <th className="cnk1n">
                                                                <div className="cqosy cob4g">Currency</div>
                                                            </th>
                                                            <th className="cnk1n">
                                                                <div className="cqosy cob4g">Created On</div>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-sm ci5zp cz8yz cw92y cxw08">
                                                        {payments.map((user, index) => (

                                                            <tr key={index}>
                                                                <td className="cnk1n">
                                                                    <div className="flex items-center">
                                                                        {/* <svg className="mr-2 c7n6y c152z" width="36" height="36" viewBox="0 0 36 36">
                                                                        <circle fill="#24292E" cx="18" cy="18" r="18"></circle>
                                                                        <path d="M18 10.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V24c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" fill="#FFF"></path>
                                                                    </svg> */}
                                                                        <div className="text-slate-800 dark:text-slate-100">{user.paymentId}</div>
                                                                    </div>
                                                                </td>
                                                                <td className="cnk1n">
                                                                    <div className="cob4g">{user.email}</div>
                                                                </td>
                                                                <td className="cnk1n">
                                                                    <div className="clfqm cob4g">{user.amount}</div>
                                                                </td>
                                                                <td className="cnk1n">
                                                                    <div className="cob4g">{user.currency}</div>
                                                                </td>
                                                                <td className="cnk1n">
                                                                    <div className="cob4g">{user.create_time}</div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""

                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row justify-center w-full" >
                    <div className="flex flex-row justify-center w-full p-[15px] bg-indigo-50_01">
                        <Text
                            size="md"
                            as="p"
                            className="mt-[3px] ml-[442px] mr-[441px] !text-gray-800_01 text-center"
                        >
                            © 2023 IQ TESTS. ALL RIGHTS RESERVED.
                        </Text>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminHome




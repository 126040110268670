import React from "react";
import PropTypes from "prop-types";

const shapes = {
  square: "rounded-none",
  round: "rounded-[24px]",
  circle: "rounded-[50%]",
};
const variants = {
  fill: {
    blue_gray_100_01: "bg-blue_gray-100_01",
    indigo_50_01: "bg-indigo-50_01 text-gray-800_01",
    white_A700: "bg-white-A700 shadow-bs",
  },
  gradient: { deep_purple_900_purple_400: "bg-gradient  text-white-A700" },
};
const sizes = { xs: "p-[3px]", sm: "p-2", md: "p-3", lg: "p-[17px]" };

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  size = "",
  variant = "",
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf(["square", "round", "circle"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  variant: PropTypes.oneOf(["fill", "gradient"]),
  color: PropTypes.oneOf([
    "blue_gray_100_01",
    "indigo_50_01",
    "white_A700",
    "deep_purple_900_purple_400",
  ]),
};

export { Button };

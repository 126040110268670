import React from "react";

import { useNavigate } from "react-router-dom";

import { Button, Img, Line, List, Text } from "components";
import Header from "components/Header";
import Review from "components/Review";
import Footer from "components/Footer";

const HomeOnePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header className="bg-gray-200 flex sm:flex-col flex-row sm:gap-5 items-center  justify-center md:px-5 shadow-bs w-full" />
      <div className="bg-white-A700 flex flex-col font-inter  items-center justify-start mx-auto w-full">
        <div className="flex flex-col md:px-5  md:py-1 relative w-full">
          <div className="bg-gradient1  flex flex-col items-center justify-start mx-auto p-[45px] md:px-10   sm:px-5 w-full">
            <div className="flex md:flex-col flex-row md:gap-10 items-center justify-between mb-[158px] w-[96%] md:w-full">
              <div className="flex flex-col items-start justify-start">
                <div
                  className="flex flex-col font-inter items-end justify-start w-3/4  	   w-[411px] md:w-full "
                  style={{
                    height: "72.84px",
                    top: "129.21px",
                    left: "71px",
                    borderRadius: "23.5px",
                  }}
                >
                  <Text
                    className="mr-0.5 sm:text-[45px] md:text-[51px] text-[59px]  text-black-900"
                    size="txtInterSemiBold59"
                    style={{ textWrap: "nowrap" }}
                  >
                    Online IQ Test
                  </Text>
                  <Line className="bg-gradient  h-1 rounded-sm w-[51%]" />
                </div>
                <Text
                  className="leading-[29.00px] mt-[17px] sm:text-[27px] md:text-[29px] text-[31px] text-black-900_01"
                  size="txtPoppinsRegular31"
                >
                  <>
                    Accurate testing. Instant
                    <br />
                    results for your IQ test.
                  </>
                </Text>
                <Text
                  className="leading-[30.00px] mt-[13px] text-gray-800 text-xl w-full"
                  size="txtPoppinsRegular20"
                >
                  Welcome to the BrainWise Institute. Our platform offers a
                  unique blend of education and entertainment for those eager to
                  unlock their minds' potential.{" "}
                </Text>
                <Button
                  className="common-pointer cursor-pointer font-inter font-semibold min-w-[177px] mt-[19px] text-[22px] text-center sm:text-lg md:text-xl"
                  onClick={() => navigate("/iqtest")}
                  shape="round"
                  size="sm"
                  variant="gradient"
                  color="deep_purple_900_purple_400"
                >
                  IQ Test
                </Button>
              </div>
              <Img
                className="h-[435px] md:h-auto object-cover"
                src="images/img_group173.png"
                alt="group173"
              />
            </div>
          </div>

          <div className="-mt-28">
            <Review />
          </div>
        </div>

        <div className="flex flex-col items-center justify-start max-w-[1184px] mt-[60px] mx-auto md:px-5 w-full">
          <div className="flex md:flex-col flex-row md:gap-10 items-center justify-between w-full">
            <Img
              className="h-[511px] w-[511px]"
              src="images/img_2895241ai.svg"
              alt="2895241ai"
            />
            <div className="flex flex-col items-start justify-start">
              <div className="flex flex-row gap-[11px] items-center justify-start w-1/4 md:w-full">
                <Text
                  className="text-black-900 text-lg"
                  size="txtInterRegular18"
                >
                  WHY US
                </Text>
                <div className="bg-gradient  h-[3px] rounded-[1px] w-[28%]"></div>
              </div>
              <Text
                className="mt-[5px] sm:text-[32px] md:text-[38px] text-[42px] text-black-900"
                size="txtInterSemiBold42"
              >
                Online IQ Test
              </Text>
              <Text
                className="leading-[24.00px] mt-[9px] text-base text-gray-800_01 w-[98%] sm:w-full"
                size="txtInterRegular16"
              >
                <span style={{ fontWeight: "bold" }}>Immediate Insights:</span>{" "}
                An instant online IQ test delivers swift feedback on your
                cognitive strengths and weaknesses, offering a clear picture of
                your intellectual capabilities right away. This immediacy is
                invaluable for those eager to gain insights without the wait.
              </Text>

              <Text
                className="leading-[24.00px] mt-[7px] text-base text-gray-800_01 w-full"
                size="txtInterRegular16"
              >
                <span style={{ fontWeight: "bold" }}>Utmost Convenience:</span>
                With the ability to take the test anytime, anywhere, you bypass
                the need for scheduled appointments or visits to testing
                facilities. This unparalleled convenience ensures the test fits
                seamlessly into even the most hectic of lifestyles.
              </Text>
              <Text
                className="leading-[24.00px] mt-[7px] text-base text-gray-800_01 w-full"
                size="txtInterRegular16"
              >
                {/* <div className="flex flex-col gap-[5px] items-start justify-start mt-[17px] w-[69%] md:w-full">
                <div className="flex flex-row gap-[13px] items-start justify-start w-[73%] md:w-full">
                  <Img
                    className="h-[13px] w-3.5"
                    src="images/img_signal.svg"
                    alt="signal"
                  />
                  <Text
                    className="text-gray-800_01 text-lg"
                    size="txtInterRegular18Gray80001"
                  >
                    Ut dignissim turpis egestas ac.
                  </Text>
                </div>
                <div className="flex sm:flex-col flex-row gap-3 items-start justify-start w-full">
                  <Img
                    className="h-[13px] w-3.5"
                    src="images/img_signal.svg"
                    alt="signal_One"
                  />
                  <Text
                    className="text-gray-800_01 text-lg"
                    size="txtInterRegular18Gray80001"
                  >
                    Donec velit velit, dapibus sit amet eros eget
                  </Text>
                </div>
                <div className="flex flex-row gap-[11px] items-start justify-start w-[72%] md:w-full">
                  <Img
                    className="h-[13px] w-3.5"
                    src="images/img_signal.svg"
                    alt="signal_Two"
                  />
                  <Text
                    className="text-gray-800_01 text-lg"
                    size="txtInterRegular18Gray80001"
                  >
                    Ut dignissim turpis egestas ac.
                  </Text>
                </div>
                <div className="flex sm:flex-col flex-row gap-3 items-start justify-start w-full">
                  <Img
                    className="h-[13px] w-3.5"
                    src="images/img_signal.svg"
                    alt="signal_Three"
                  />
                  <Text
                    className="text-gray-800_01 text-lg"
                    size="txtInterRegular18Gray80001"
                  >
                    Donec velit velit, dapibus sit amet eros eget
                  </Text>
                </div>
              </div> */}
                <Text
                  className="leading-[24.00px] mt-[7px] text-base text-gray-800_01 w-full"
                  size="txtInterRegular16"
                >
                  <span style={{ fontWeight: "bold" }}>
                    Encourages Personal Development:{" "}
                  </span>
                  Gaining an understanding of your IQ level can be a powerful
                  motivator, encouraging you to undertake new cognitive
                  challenges and embrace opportunities for learning and personal
                  development.{" "}
                </Text>
                <Text
                  className="leading-[24.00px] mt-[7px] text-base text-gray-800_01 w-full"
                  size="txtInterRegular16"
                >
                  <span style={{ fontWeight: "bold" }}>
                    Insights for Career and Education:
                  </span>
                  Your IQ test results can shine a light on potential career
                  paths or educational opportunities that align with your
                  cognitive profile, aiding in making informed decisions about
                  your future.
                </Text>
                <Text
                  className="leading-[24.00px] mt-[7px] text-base text-gray-800_01 w-full"
                  size="txtInterRegular16"
                >
                  {" "}
                  <span style={{ fontWeight: "bold" }}>
                    Personal Benchmarking:
                  </span>
                  Undertaking an IQ test sets a personal baseline, enabling you
                  to track your cognitive progress over time. This benchmark is
                  instrumental in recognizing and celebrating cognitive growth
                  and milestones.{" "}
                </Text>
              </Text>
              <Button
                className="cursor-pointer font-semibold min-w-[173px] mt-[21px] rounded-[23px] text-center text-lg"
                shape="round"
                size="sm"
                variant="gradient"
                color="deep_purple_900_purple_400"
                onClick={() => navigate("/about")}
              >
                READ MORE
              </Button>
            </div>
          </div>
        </div>
        <div className="bg-gray-100 flex flex-col items-center justify-start mt-[67px] p-[35px] sm:px-5 w-full">
          <div className="flex md:flex-col flex-row gap-[39px] items-end justify-start max-w-[1158px] mx-auto md:px-5 w-full">
            <div className="flex md:flex-1 flex-col items-center justify-start mb-[3px] md:mt-0 mt-[88px] w-[47%] md:w-full">
              <div className="flex flex-col gap-[37px] items-center justify-start w-full">
                <div className="flex flex-col gap-1.5 items-center justify-start">
                  <Text
                    className="text-3xl sm:text-[26px] md:text-[28px] text-black-900"
                    size="txtInterSemiBold30"
                  >
                    Try the test now!
                  </Text>
                  <Text
                    className="leading-[23.00px] text-[17px] text-center text-gray-800_01 w-[96%] sm:w-full"
                    size="txtInterSemiBold17"
                  >
                    Find out how smart you are by answering the question
                  </Text>
                </div>
                <Img
                  className="h-[166px]"
                  src="images/img_search.svg"
                  alt="search"
                />
              </div>
            </div>
            <div className="flex md:flex-1 flex-col gap-[15px] items-center justify-start w-[51%] md:w-full">
              <Text
                className="leading-[33.00px] sm:text-[17px] md:text-[19px] text-[21px] text-black-900 text-center w-4/5 sm:w-full"
                size="txtInterMedium21"
              >
                Choose one of the variants (A, B, C, D) to replace the missing
                shape
              </Text>
              <div className="flex sm:flex-col md:text-center flex-row sm:gap-[45px] items-end md:items-center justify-between w-full">
                <Img
                  className="h-[303px]"
                  src="images/img_group133.svg"
                  alt="group133"
                />
                <div className="flex flex-col items-center justify-start mb-1 sm:mt-0 mt-[23px]">
                  <div className="flex flex-col items-center justify-start w-full">
                    <div className="gap-[42px] grid grid-cols-2 justify-center min-h-[auto] w-full">
                      <div className="flex flex-1 flex-col items-center justify-start w-full">
                        <div className="flex flex-col items-center justify-start w-[101px] md:w-full">
                          <div className="h-[102px] relative w-[101px]">
                            <div className="absolute h-[102px] inset-[0] justify-center m-auto w-[101px]">
                              <div className="absolute flex flex-row gap-[34px] h-full inset-[0] items-start justify-between m-auto w-[101px]">
                                <div className="h-[34px] relative w-[34px]">
                                  <Img
                                    className="h-[34px] m-auto w-[34px]"
                                    src="images/img_contrast.svg"
                                    alt="contrast"
                                  />
                                  <Img
                                    className="absolute h-[34px] inset-[0] justify-center m-auto w-[34px]"
                                    src="images/img_vector.svg"
                                    alt="vector"
                                  />
                                </div>
                                <div className="md:h-[102px] h-[68px] mt-[34px] relative w-[33%]">
                                  <Img
                                    className="h-[67px] m-auto"
                                    src="images/img_user.svg"
                                    alt="user"
                                  />
                                  <Img
                                    className="absolute h-[68px] inset-[0] justify-center m-auto"
                                    src="images/img_bookmark.svg"
                                    alt="bookmark"
                                  />
                                </div>
                              </div>
                              <Img
                                className="absolute h-[34px] inset-x-[0] mx-auto top-[0] w-[34px]"
                                src="images/img_contrast.svg"
                                alt="contrast_One"
                              />
                              <Img
                                className="absolute h-[34px] inset-x-[0] mx-auto top-[0] w-[34px]"
                                src="images/img_checkmark.svg"
                                alt="checkmark"
                              />
                            </div>
                            <Img
                              className="absolute h-[34px] inset-[0] justify-center m-auto w-[34px]"
                              src="images/img_settings.svg"
                              alt="settings"
                            />
                            <Img
                              className="absolute h-[34px] inset-[0] justify-center m-auto w-[34px]"
                              src="images/img_checkmark_white_a700.svg"
                              alt="checkmark_One"
                            />
                          </div>
                        </div>
                        <Text
                          className="text-black-900 text-center text-xl"
                          size="txtInterMedium20"
                        >
                          A
                        </Text>
                      </div>
                      <div className="flex flex-1 flex-col items-center justify-start w-full">
                        <div className="h-[102px] relative w-[101px]">
                          <div className="absolute h-[102px] inset-[0] justify-center m-auto w-[101px]">
                            <div className="absolute flex flex-row gap-[34px] h-full inset-[0] items-start justify-between m-auto w-[101px]">
                              <div className="h-[34px] relative w-[34px]">
                                <Img
                                  className="h-[34px] m-auto w-[34px]"
                                  src="images/img_contrast.svg"
                                  alt="contrast"
                                />
                                <Img
                                  className="absolute h-[34px] inset-[0] justify-center m-auto w-[34px]"
                                  src="images/img_vector.svg"
                                  alt="vector"
                                />
                              </div>
                              <div className="md:h-[102px] h-[68px] mt-[34px] relative w-[33%]">
                                <Img
                                  className="h-[67px] m-auto"
                                  src="images/img_user.svg"
                                  alt="user"
                                />
                                <Img
                                  className="absolute h-[68px] inset-[0] justify-center m-auto"
                                  src="images/img_bookmark.svg"
                                  alt="bookmark"
                                />
                              </div>
                            </div>
                            <Img
                              className="absolute h-[34px] inset-x-[0] mx-auto top-[0] w-[34px]"
                              src="images/img_contrast.svg"
                              alt="contrast_One"
                            />
                            <Img
                              className="absolute h-[34px] inset-x-[0] mx-auto top-[0] w-[34px]"
                              src="images/img_checkmark.svg"
                              alt="checkmark"
                            />
                          </div>
                          <Img
                            className="absolute h-[34px] inset-[0] justify-center m-auto w-[34px]"
                            src="images/img_settings.svg"
                            alt="settings"
                          />
                          <Img
                            className="absolute h-[34px] inset-[0] justify-center m-auto w-[34px]"
                            src="images/img_checkmark_white_a700.svg"
                            alt="checkmark_One"
                          />
                        </div>
                        <Text
                          className="text-black-900 text-center text-xl"
                          size="txtInterMedium20"
                        >
                          B
                        </Text>
                      </div>
                      <div className="flex flex-1 flex-col items-center justify-start w-full">
                        <div className="flex flex-col h-[104px] items-center justify-start w-[104px]">
                          <div className="h-[104px] relative w-[104px]">
                            <div className="absolute h-[104px] inset-[0] justify-center m-auto w-[104px]">
                              <div className="absolute flex flex-row gap-[34px] h-full inset-[0] items-start justify-between m-auto w-[104px]">
                                <div className="h-[35px] relative w-[35px]">
                                  <Img
                                    className="h-[34px] m-auto w-[34px]"
                                    src="images/img_contrast.svg"
                                    alt="contrast"
                                  />
                                  <Img
                                    className="absolute h-[35px] inset-[0] justify-center m-auto w-[35px]"
                                    src="images/img_vector.svg"
                                    alt="vector"
                                  />
                                </div>
                                <div className="md:h-[103px] h-[69px] mt-[34px] relative w-[33%]">
                                  <Img
                                    className="h-[68px] m-auto"
                                    src="images/img_user.svg"
                                    alt="user"
                                  />
                                  <Img
                                    className="absolute h-[69px] inset-[0] justify-center m-auto"
                                    src="images/img_bookmark.svg"
                                    alt="bookmark"
                                  />
                                </div>
                              </div>
                              <Img
                                className="absolute h-[34px] inset-x-[0] mx-auto top-[0] w-[34px]"
                                src="images/img_contrast.svg"
                                alt="contrast_One"
                              />
                              <Img
                                className="absolute h-[35px] inset-x-[0] mx-auto top-[0] w-[35px]"
                                src="images/img_checkmark.svg"
                                alt="checkmark"
                              />
                            </div>
                            <Img
                              className="absolute h-[34px] inset-[0] justify-center m-auto w-[34px]"
                              src="images/img_settings.svg"
                              alt="settings"
                            />
                            <Img
                              className="absolute h-[35px] inset-[0] justify-center m-auto w-[35px]"
                              src="images/img_checkmark_white_a700.svg"
                              alt="checkmark_One"
                            />
                          </div>
                        </div>
                        <Text
                          className="text-black-900 text-center text-xl"
                          size="txtInterMedium20"
                        >
                          C
                        </Text>
                      </div>
                      <div className="flex flex-1 flex-col items-center justify-start w-full">
                        <div className="flex flex-col items-center justify-start w-[101px] md:w-full">
                          <div className="h-[102px] relative w-[101px]">
                            <div className="absolute h-[102px] inset-[0] justify-center m-auto w-[101px]">
                              <div className="absolute flex flex-row gap-[34px] h-full inset-[0] items-start justify-between m-auto w-[101px]">
                                <div className="h-[34px] relative w-[34px]">
                                  <Img
                                    className="h-[34px] m-auto w-[34px]"
                                    src="images/img_contrast.svg"
                                    alt="contrast"
                                  />
                                  <Img
                                    className="absolute h-[34px] inset-[0] justify-center m-auto w-[34px]"
                                    src="images/img_vector.svg"
                                    alt="vector"
                                  />
                                </div>
                                <div className="md:h-[102px] h-[68px] mt-[34px] relative w-[33%]">
                                  <Img
                                    className="h-[67px] m-auto"
                                    src="images/img_user.svg"
                                    alt="user"
                                  />
                                  <Img
                                    className="absolute h-[68px] inset-[0] justify-center m-auto"
                                    src="images/img_bookmark.svg"
                                    alt="bookmark"
                                  />
                                </div>
                              </div>
                              <Img
                                className="absolute h-[34px] inset-x-[0] mx-auto top-[0] w-[34px]"
                                src="images/img_contrast.svg"
                                alt="contrast_One"
                              />
                              <Img
                                className="absolute h-[34px] inset-x-[0] mx-auto top-[0] w-[34px]"
                                src="images/img_checkmark.svg"
                                alt="checkmark"
                              />
                            </div>
                            <Img
                              className="absolute h-[34px] inset-[0] justify-center m-auto w-[34px]"
                              src="images/img_settings.svg"
                              alt="settings"
                            />
                            <Img
                              className="absolute h-[34px] inset-[0] justify-center m-auto w-[34px]"
                              src="images/img_checkmark_white_a700.svg"
                              alt="checkmark_One"
                            />
                          </div>
                        </div>
                        <Text
                          className="text-black-900 text-center text-xl"
                          size="txtInterMedium20"
                        >
                          D
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="h-[539px] md:h-[770px] md:px-5 relative w-full">
          <Img
            className="h-[539px] m-auto object-cover w-full"
            src="images/img_rectangle35.png"
            alt="rectangleThirtyFive"
          />
          <div className="absolute bg-black-900_7f flex flex-col h-full inset-[0] items-center justify-center m-auto p-[49px] md:px-10 sm:px-5 w-full">
            <div className="flex md:flex-col flex-row md:gap-10 gap-[66px] items-start justify-start w-[95%] md:w-full">
              <Img
                className="h-[441px]"
                src="images/img_search.svg"
                alt="iqgraphdarkOne"
              />
              <div className="flex flex-col items-center justify-start md:mt-0 mt-[60px] w-[44%] md:w-full">
                <div className="flex flex-col gap-1.5 items-start justify-start w-full">
                  <Text
                    className="sm:text-4xl md:text-[42px] text-[46px] text-white-A700"
                    size="txtInterSemiBold46"
                  >
                    Why this IQ test?
                  </Text>
                  <Text
                    className="leading-[24.00px] text-white-A700 text-xl w-full"
                    size="txtInterRegular20"
                  >
                    The HMI™ Certification is the most accurate and regognized
                    online IQ test available, created by professionals in
                    various research fields. It is highly accurate and
                    specifically designed for measuring general intelligence
                    among the entire population.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="flex flex-col items-center justify-start max-w-[1176px] mt-[49px] mx-auto md:px-5 w-full">
          <div className="flex flex-col gap-6 items-center justify-start w-full">
            <div className="flex md:flex-col flex-row md:gap-5 items-center justify-start w-[98%] md:w-full">
              <Text
                className="leading-[47.00px] md:text-3xl sm:text-[28px] text-[32px] text-black-900"
                size="txtInterSemiBold32"
              >
                <>
                  Why take an instant
                  <br />
                  IQ test online?
                </>
              </Text>
              <div className="bg-white-A700 border border-gray-200_02 border-solid flex flex-col items-center justify-end md:ml-[0] ml-[57px] p-[22px] sm:px-5 shadow-bs2 w-[33%] md:w-full">
                <div className="flex flex-col items-center justify-start w-[95%] md:w-full">
                  <Img
                    className="h-[70px] md:h-auto object-cover w-[69px] sm:w-full"
                    src="images/img_lineicon11.png"
                    alt="lineiconEleven"
                  />
                  <Text
                    className="mt-[21px] text-[22px] text-black-900 text-center sm:text-lg md:text-xl"
                    size="txtInterMedium22"
                  >
                    Elevate Your Mind
                  </Text>
                  <Text
                    className="leading-[24.00px] mt-[9px] text-center text-gray-700 text-lg w-full"
                    size="txtInterRegular18Gray700"
                  >
                    Test Your Cognitive Ability Today!.
                  </Text>
                </div>
              </div>
              <div className="bg-white-A700 border border-gray-200_02 border-solid flex flex-col items-center justify-end ml-6 md:ml-[0] p-[22px] sm:px-5 shadow-bs2 w-[33%] md:w-full">
                <div className="flex flex-col items-center justify-start mt-0.5 w-[95%] md:w-full">
                  <Img
                    className="h-[70px] md:h-auto object-cover"
                    src="images/img_settings_black_900.png"
                    alt="settings"
                  />
                  <Text
                    className="mt-5 text-[22px] text-black-900 text-center sm:text-lg md:text-xl"
                    size="txtInterMedium22"
                  >
                    Certify Your Brilliance.
                  </Text>
                  <Text
                    className="leading-[24.00px] mt-[9px] text-center text-gray-700 text-lg w-full"
                    size="txtInterRegular18Gray700"
                  >
                    Receive Your IQ Certification Today!
                  </Text>
                </div>
              </div>
            </div>
            <div className="flex md:flex-col flex-row md:gap-10 items-center justify-between w-full">
              <div className="bg-white-A700 border border-gray-200_02 border-solid flex md:flex-1 flex-col items-center justify-end p-[21px] sm:px-5 shadow-bs2 w-[32%] md:w-full">
                <div className="flex flex-col items-center justify-start mt-[3px] w-[95%] md:w-full">
                  <Img
                    className="h-[70px] md:h-auto object-cover w-[69px] sm:w-full"
                    src="images/img_lineicon11.png"
                    alt="lineiconEleven_One"
                  />
                  <Text
                    className="mt-[21px] text-[22px] text-black-900 text-center sm:text-lg md:text-xl"
                    size="txtInterMedium22"
                  >
                    Elevate Your Intelligence.
                  </Text>
                  <Text
                    className="leading-[24.00px] mt-[9px] text-center text-gray-700 text-lg w-full"
                    size="txtInterRegular18Gray700"
                  >
                    Boost Your IQ Levels Today with Our Special Program and
                    Games!
                  </Text>
                </div>
              </div>
              <div className="bg-white-A700 border border-gray-200_02 border-solid flex md:flex-1 flex-col items-center justify-end p-[21px] sm:px-5 shadow-bs2 w-[32%] md:w-full">
                <div className="flex flex-col gap-5 items-center justify-start mt-1 w-[95%] md:w-full">
                  <Img
                    className="h-[70px] w-[69px]"
                    src="images/img_search.svg"
                    alt="lineiconTwentyOne"
                  />
                  <div className="flex flex-col gap-2.5 items-center justify-start w-full">
                    <Text
                      className="text-[22px] text-black-900 text-center sm:text-lg md:text-xl"
                      size="txtInterMedium22"
                    >
                      Set Your Benchmark
                    </Text>
                    <Text
                      className="leading-[24.00px] text-center text-gray-700 text-lg w-full"
                      size="txtInterRegular18Gray700"
                    >
                      Excel with IQ Tests Today!
                    </Text>
                  </div>
                </div>
              </div>
              <div className="bg-white-A700 border border-gray-200_02 border-solid flex md:flex-1 flex-col items-center justify-end p-[22px] sm:px-5 shadow-bs2 w-[32%] md:w-full">
                <div className="flex flex-col items-center justify-start w-[95%] md:w-full">
                  <Img
                    className="h-[70px] md:h-auto object-cover w-[69px] sm:w-full"
                    src="images/img_lineicon11.png"
                    alt="lineiconEleven_Two"
                  />
                  <Text
                    className="mt-[21px] text-[22px] text-black-900 text-center sm:text-lg md:text-xl"
                    size="txtInterMedium22"
                  >
                    Discover Your Brilliance
                  </Text>
                  <Text
                    className="leading-[24.00px] mt-[9px] text-center text-gray-700 text-lg w-full"
                    size="txtInterRegular18Gray700"
                  >
                    Our IQ Test, Where Wonder Meets Fun!
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-80 md:h-[470px] max-w-[1182px] mt-[50px] mx-auto  relative w-full">
          <Img
            className="h-80 m-auto object-cover  md:h-[470px]  rounded-[10px] w-full"
            src="images/img_rectangle40.png"
            alt="rectangleForty"
          />
          <div className="absolute bg-black-900_ba flex flex-col h-full inset-[0] items-center justify-center m-auto p-[59px] md:px-10 sm:px-5 rounded-[10px] w-full">
            <div className="flex flex-col items-center justify-start mb-[5px] w-full">
              <div className="flex flex-col items-center justify-start w-full">
                <div className="flex flex-col items-center justify-start w-full">
                  <Text
                    className="sm:text-[18px] md:text-[20px] text-[46px] text-center text-white-A700"
                    size="txtInterSemiBold46"
                  >
                    Choose BrainWise Institute for{" "}
                  </Text>
                  <Text
                    className="leading-[32.00px] mt-1.5 sm:text-[16px] md:text-[16px] text-[18px] text-center text-white-A700 w-full"
                    size="txtInterRegular25"
                  >
                    a blend of science-backed methods and engaging experiences.
                    With AI technology, personalized reports, and handcrafted
                    plans, we make cognitive enhancement effective and fun. Our
                    commitment to accessibility ensures everyone can embark on a
                    transformative journey of self-discovery. Join us where
                    learning meets excitement and celebrate every step forward
                  </Text>
                  <Button
                    className="cursor-pointer font-semibold min-w-[209px] mt-7 rounded-[26px] text-[19px] text-center"
                    shape="round"
                    size="md"
                    variant="gradient"
                    color="deep_purple_900_purple_400"
                    onClick={() => navigate("/contact")}
                  >
                    CONTACT NOW
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Text
          className="mt-[50px] sm:text-[38px] md:text-[44px] text-[52px] text-black-900 text-center"
          size="txtInterSemiBold52"
        >
          IQTest
        </Text>
        <Footer />
      </div>
    </>
  );
};

export default HomeOnePage;

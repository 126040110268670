import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "pages/NotFound";
import LoadingScreen from "components/loadingScreen";
import AdminHome from "pages/Admin";
import Protectted from "components/Protectted";
import Review from "components/Review";
import PricingOne from  "pages/PricingOne"
import Profile  from  "pages/Profile"
import IQResult from  "pages/IQResult"
import Iqtest from  "pages/Iqtest"
import About from   "pages/About"
import Contact from   "pages/Contact"
import Login from  "pages/Login"
import HomeOne from  "pages/HomeOne"
import Checkout from  "pages/Checkout"
import PaymentSuccess from  "pages/Payment"
import CancelPayment  from "pages/CancelPayment"
import SudokuPage from  "pages/SudokuPage"
import TicTacGamePage from  "pages/TicTacGamePage"
import MemoryCardPage from  "pages/MemoryCardPage"
import TermsCondition from "pages/TermsCondition";
import PrivicyPolicy from "pages/PrivicyPolicy";

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <LoadingScreen />
      <Router>
        <Routes>
          {/* <Route path="/" element={<Home1 />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/iqtest" element={<Iqtest />} />
          <Route path="/iqresult" element={<IQResult />} />
          <Route path="/profile" element={<Protectted Component={Profile} />} />
          <Route path="/pricing" element={<PricingOne />} />
          <Route path="/SudokuPage" element={ <Protectted Component={SudokuPage} />} />
          <Route path="/TicTacGamePage" element={<Protectted Component={TicTacGamePage} />} />
          <Route path="/MemoryCardPage" element={<Protectted Component={MemoryCardPage} />} />
          <Route path="/admin" element={<Protectted Component={AdminHome} />} />
          <Route path="/" element={<HomeOne />} />
          <Route path="/iqresult" element={<IQResult />} />
          <Route path="/checkout" index element={<Checkout />} />
          <Route path="/TermsCondition" index element={<TermsCondition />} />
          <Route path='/privicyPolicy' index element={<PrivicyPolicy/>} />

          <Route
            path="/payment/success/:userId/:paymentId"
            index
            element={<PaymentSuccess />}
          />
          <Route path="/payment/cancel" index element={<CancelPayment />} />
          {/* <Route path="/admin" element={<AdminHome/>} /> */}
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;

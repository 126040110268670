import React, { useState, useEffect } from 'react';

function Timer({getTime }) {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds + 1);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  

  useEffect(() => {
    if (seconds === 60) {
      setSeconds(0);
      setMinutes(prevMinutes => prevMinutes + 1);
    }
    if (minutes === 60) {
      setMinutes(0);
      setHours(prevHours => prevHours + 1);
    }
    getTime(`${pad(minutes)}:${pad(seconds)}`)
  }, [seconds, minutes]);

  const formattedTime = `${pad(minutes)}:${pad(seconds)}`;

  return (
    <div>
      <div>{formattedTime}</div>
      {/* You can add buttons to start, stop, and reset the timer here */}
    </div>
  );
}

function pad(value) {
  return value < 10 ? `0${value}` : value;
}

export default Timer;

import React from "react";
import Routes from "./Routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <ToastContainer />
      <Routes />;
    </div>
  );
}

export default App;

import React from "react";

import { Button, Img, Line, Text } from "components";
import Header from "components/Header";
import SudokuGames from "components/SudokuGame";
import Timer from "components/Timer";
import TicTacGame from "components/TicTacGame";
import MemoryCardGame from "components/MemoryCardGame";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";
const MemoryCardPage = () => {
  const navigate = useNavigate();
  const getTime = (time) => {
    
  };
  return (
    <>
      <Header className="bg-gray-200 flex sm:flex-col flex-row sm:gap-5 items-center justify-center md:px-5 shadow-bs1 w-full" />
      <div className="bg-white-A700 flex flex-col font-inter items-center justify-start mx-auto w-full">
    
        <div className="bg-gradient1  flex flex-col items-center justify-end p-7 sm:px-5 w-full">
          <div className="flex sm:flex-col flex-row md:gap-10 items-center justify-between max-w-[1175px] mx-auto md:px-5 w-full">
            <div className="flex flex-col gap-3 items-start justify-start">
              <Text
                className="sm:text-[32px] md:text-[38px] text-[42px] text-black-900"
                size="txtInterSemiBold42"
              >
                Memory Card
              </Text>
              <div className="flex flex-row font-poppins items-center justify-between w-full">
                <Text
                  className="text-black-900_01 text-xl"
                  size="txtPoppinsRegular20"
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  HOME
                </Text>
                <Img
                  className="h-[11px]"
                  src="images/img_arrowright.svg"
                  alt="arrowright"
                />
                <Text
                  className="text-deep_purple-800_01 text-xl"
                  size="txtPoppinsMedium20"
                >
                  Memory Card
                </Text>
              </div>
            </div>
            <Img
              className="h-[243px] md:h-auto object-cover"
              src="images/img_2998315ai1.png"
              alt="2998315aiOne"
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-start max-w-[1168px] mt-6 mx-auto md:px-5 w-full">
          <div className="flex flex-col gap-6 items-center justify-start w-full">
            <div className="flex md:flex-col flex-row md:gap-10 items-center justify-between w-full">
              <div className="flex flex-col items-center justify-start">
                <Text
                  className="sm:text-[17px] md:text-[19px] text-[21px] text-black-900"
                  size="txtInterMedium21"
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </Text>
              </div>
              <div className="bg-indigo-50 flex flex-col h-[67px] md:h-auto items-start justify-start px-[11px] py-2 rounded-[5px] w-[67px]">
                <div className="flex flex-col items-center justify-start w-[96%]">
                  <Img
                    className="h-[30px] w-[30px]"
                    src="images/img_10114438941543238869.svg"
                    alt="101144389415432"
                  />
                    <Timer getTime={getTime} />
                </div>
              </div>
            </div>
            <div className="flex md:flex-col flex-row gap-[35px] items-center justify-between w-full">
              <MemoryCardGame />
            </div>
          </div>
        </div>
       <Footer/>
      </div>
    </>
  );
};

export default MemoryCardPage;
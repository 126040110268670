import React from "react";
import { useSelector } from "react-redux";

function LoadingScreen() {
  const isLoading = useSelector((state) => state.user.isLoading);

  return (
    <div>
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-slate-900 bg-opacity-50 z-50">
          <div className="flex flex-row items-center">
            {/* <div className=" animate-spin loader-circle relative rounded-full border-8 border-t-8 border-gray-200 h-20 w-20"></div> */}
            <p className="text-slate-100 text-2xl mt-4">Loading </p>
            <span className="relative mt-8 ml-1 flex h-1 w-1">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-1 w-1 bg-sky-500"></span>
            </span>
            <span className="relative mt-8 ml-1 flex h-1 w-1">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-1 w-1 bg-sky-500"></span>
            </span>
            <span className="relative mt-8 ml-1 flex h-1 w-1">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-1 w-1 bg-sky-500"></span>
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoadingScreen;

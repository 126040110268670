import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Button, Img, Text } from "components";
import Header from "components/Header";
import MCQQuestion from "components/MCQQuestion";
import Timer from "components/Timer";
import Footer from "components/Footer";

const IqtestPage = () => {
  const navigate = useNavigate();
  let duration = 0;
  let question = [
    { no: 1, ans: "A", image: "1", Options: ["A", "B", "C", "D"] },
    { no: 2, image: "2", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 3, image: "3", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 4, image: "4", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 5, image: "5", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "6", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "7", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "8", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "9", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "10", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "11", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "12", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "13", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "14", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "15", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "16", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "17", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "18", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "19", ans: "A", Options: ["A", "B", "C", "D"] },
    { image: "20", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 21, image: "22", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 22, image: "23", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 24, image: "24", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 25, image: "25", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 26, image: "27", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 27, image: "28", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 28, image: "29", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 29, image: "30", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 30, image: "31", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 31, image: "32", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 32, image: "33", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 33, image: "34", ans: "A", Options: ["A", "B", "C", "D"] },
    { no: 34, image: "35", ans: "A", Options: ["A", "B", "C", "D"] },
  ];
  const [selectQuestion, setSelectQuestion] = useState(1);
  const [selectAnswers, setSelectAnswers] = useState([]);

  const getTime = (time) => {
    duration = time;
    console.log(duration);
  };

  const handleOptionSelection = (selected) => {
    console.log("selected", selected);
    let temp = [...selectAnswers, { ans: selected, que: selectQuestion }];
    setSelectAnswers(temp);
    setTimeout(() => {
      if (selectQuestion === question.length) {
        calculateIqScore(temp);
        console.log("selectAnswers", selectAnswers);
      } else {
        setSelectQuestion(selectQuestion + 1);
      }
    }, [1000]);
  };

  const calculateIqScore = (answers) => {
    let countScore = 0;
    answers.map(({ ans, que }) => {
      if (ans === "A") {
        countScore += 1;
      }
    });
    let testReport = JSON.stringify({
      duration,
      attempted: answers.length,
      iqScore: countScore,
      total: question.length,
    });
    localStorage.setItem("IqScore", testReport);
    navigate("/iqresult");
  };

  //  console.log("selectQuestion0" , selectQuestion)

  const testProgress = (selectQuestion / (question.length + 1)) * 100 + "%";
  const isSkipQuestion = selectQuestion === question.length ?? true;
  return (
    <>
      <Header className="bg-gray-200 flex sm:flex-col flex-row sm:gap-5 items-center justify-center md:px-5 shadow-bs2 w-full" />
      <div className="bg-white-A700 flex flex-col font-inter items-center justify-start mx-auto w-full">
        <div className="bg-gradient1  flex flex-col items-center justify-end p-7 sm:px-5 w-full">
          <div className="flex sm:flex-col flex-row md:gap-10 items-center justify-between max-w-[1175px] mx-auto md:px-5 w-full">
            <div className="flex flex-col gap-2 items-start justify-start">
              <Text
                className="sm:text-[32px] md:text-[38px] text-[42px] text-black-900"
                size="txtInterSemiBold42"
              >
                IQ Test
              </Text>
              <div className="flex flex-row font-poppins items-center justify-between w-full">
                <Text
                  className="text-black-900_01 text-xl"
                  size="txtPoppinsRegular20Black90001"
                  onClick={() => navigate("/")}
                >
                  HOME
                </Text>
                <Img
                  className="h-[11px]"
                  src="images/img_arrowright.svg"
                  alt="arrowright"
                />
                <Text
                  className="text-deep_purple-800_02 text-xl"
                  size="txtPoppinsMedium20"
                >
                  IQ TEST
                </Text>
              </div>
            </div>
            <Img
              className="h-[243px] md:h-auto object-cover"
              src="images/img_2998315ai1.png"
              alt="2998315aiOne"
            />
          </div>
        </div>
        <div className="mb-4 max-w-[1144px] mt-[83px] mx-auto px-4 w-full sm:my-7">
          <Text
            className="text-[22px] text-black-900 sm:text-lg md:text-xl"
            size="txtInterMedium22"
          >
            {selectQuestion === 29 || selectQuestion === 30
              ? "Choose one of the variants to how cubes are"
              : "Choose one of the variants to replace the missing shape"}
          </Text>

          <div className="flex flex-row items-start justify-start mx-auto w-full">
            <div className="w-full">
              <div className="h-[5px] mt-5 overflow-hidden relative w-full">
                <div className="w-full h-full absolute bg-indigo_50_01 rounded-[2px]"></div>
                <div
                  className="h-full absolute bg-gradient-to-r from-[#2610c0] to-[#ae2ad1]  rounded-[2px]"
                  style={{ width: testProgress }}
                ></div>
              </div>
              <Text
                className=" mt-[15px] text-[22px] text-black-900 sm:text-lg md:text-xl tracking-[1.32px]"
                size="txtInterMedium22"
              >
                {selectQuestion}/{question.length}
              </Text>
            </div>
            <div className="bg-indigo-50_01 h-fit flex flex-col items-center justify-end px-3 py-2 rounded-[5px]">
              <div className="flex flex-col items-center justify-start w-[96%]">
                <Img
                  className="h-[30px] w-[30px]"
                  src="images/img_10114438941543238869.svg"
                  alt="101144389415432"
                />

                <div className="mt-[3px] text-[15px] text-black-900 text-center tracking-[0.90px]">
                  <Timer getTime={getTime} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-start   mx-auto md:px-5 md:w-full  w-5/6">
          <div className="flex flex-col gap-11 items-center justify-start w-full">
            <MCQQuestion
              no={question[selectQuestion-1].image}
              options={question[selectQuestion - 1].Options}
              handleOptionSelection={handleOptionSelection}
            />
            {isSkipQuestion ? (
              <Button
                className="cursor-pointer bg-lime-600 font-semibold min-w-[226px] rounded-[27px] text-center text-xl"
                size="md"
                variant="gradient"
                onClick={() => calculateIqScore(selectAnswers)}
              >
                Get Results
              </Button>
            ) : (
              <Button
                className="cursor-pointer font-semibold min-w-[226px] rounded-[27px] text-center text-xl"
                size="md"
                variant="gradient"
                color="deep_purple_900_purple_400"
                onClick={() => setSelectQuestion(selectQuestion + 1)}
              >
                SKIP QUESTION
              </Button>
            )}
          </div>
        </div>

        <div className="h-80 md:h-[401px] max-w-[1182px] mt-[81px] mx-auto md:px-5 relative w-full">
          <Img
            className="h-80 m-auto object-cover rounded-[10px] w-full"
            src="images/img_rectangle40.png"
            alt="rectangleForty"
          />
          <div className="absolute bg-black-900_ba flex flex-col h-full inset-[0] items-center justify-center m-auto p-[59px] md:px-10 sm:px-5 rounded-[10px] w-full">
            <div className="flex flex-col items-center justify-start mb-[5px] w-[74%] md:w-full">
              <div className="flex flex-col items-center justify-start w-full">
                <div className="flex flex-col items-center justify-start w-full">
                  <Text
                    className="sm:text-4xl md:text-[42px] text-[46px] text-center text-white-A700"
                    size="txtInterSemiBold46"
                  >
                    Online IQ Test lorem ipsum
                  </Text>
                  <Text
                    className="leading-[32.00px] mt-1.5 sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 w-full"
                    size="txtInterRegular25"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur ut massa dui. Aenean posuere turpis sed enim
                    lacinia eleifend.
                  </Text>
                  <Button
                    className="cursor-pointer font-semibold min-w-[209px] mt-7 rounded-[26px] text-[19px] text-center"
                    shape="round"
                    size="md"
                    variant="gradient"
                    color="deep_purple_900_purple_400"
                  >
                    CONTACT NOW
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default IqtestPage;

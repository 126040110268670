import React from 'react'
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { CiStar } from "react-icons/ci";


function Review() {

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,

    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };


  const cards = [
    {
      id: 1,
      title: "Card 1",
      content: "I recently tried the IQ testing and was thoroughly impressed by the depth and quality of the questions. It was challenging yet engaging, pushing me to think critically and creatively.",
      imgPath: "/public/card1.jpg",
      star: <CiStar />,
      name: 'Andrew Austin'
    },
    {
      id: 2,
      title: "Card 2",
      content: "Problem-solving, and attention to detail. I've noticed a significant improvement in my daily cognitive tasks. Plus, it's so much fun, I look forward to my daily training sessions!",
      imgPath: "/public/card2.jpg",
      star: <CiStar />,
      name: 'Emma Thompson'
    },
    {
      id: 3,
      title: "Card 3",
      content: "From the moment I landed on the website, I knew I was in for a quality experience. The user interface is clean, intuitive,",
      imgPath: "/public/card3.jpg",
      star: <CiStar />,
      name: 'Olivia Martinez'
    },
    {
      id: 4,
      title: "Card 3",
      content: "  After using the brain training program for a few weeks, I can confidently say it has contributed significantly to my personal development.     ",
      imgPath: "/public/card3.jpg",
      star: <CiStar />,
      name: 'Liam Johnson'
    },
    {
      id: 5,
      title: "Card 3",
      content: "   If you're curious about your IQ or looking to enhance your cognitive abilities, this is the perfect platform. The IQ test is thought-provoking and well-constructed, offering a great challenge.",
      imgPath: "/public/card3.jpg",
      star: <CiStar />,
      name: 'Sophia Davis'
    },
    {
      id: 6,
      title: "Card 3",
      content: "  After using the brain training program for a few weeks, I can confidently say it has contributed significantly to my personal development.  ",
      imgPath: "/public/card3.jpg",
      star: <CiStar />,
      name: 'Andrew Austin'
    },
    // Add more cards as needed
  ];

  return (
    <div className='my-2' >

      <Carousel
        responsive={responsive}
        ssr
        infinite
        // autoPlay={true}
        autoPlaySpeed={2000}
      >
        {cards.map((item) => (

          <div class="h-52 mx-2 sm:h-64 border border-gray-200 rounded-lg shadow "
            style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", textAlign: 'justify' }} >
            <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 rounded-t-lg bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:bg-gray-800" id="defaultTab" data-tabs-toggle="#defaultTabContent" role="tablist">
              {/* <li class="me-2">
            <button id="about-tab" data-tabs-target="#about" type="button" role="tab" aria-controls="about" aria-selected="true" >personal Reviews</button>
        </li> */}

            </ul>
            <div id="defaultTabContent  bg-blue-200">
              <div class=" p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800" id="about" role="tabpanel" aria-labelledby="about-tab">
                <h2 className="mb-3 text-xl flex font-extrabold tracking-tight text-gray-900 dark:text-white">
                  {Array.from({ length: 4 }).map((_, index) => (

                    <span key={index} className="text-slate-200 bg-green-600 p-0.5 mr-0.5">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path fill="#ffffff" d="M7.5 21.6l1.6-7.4L3 9.4l7-1.1 3.1-6.3 3.1 6.2 7 1.2-5.1 5.2 1.6 7.3-6.6-3.9z" />
                      </svg>
                    </span>
                  ))}
                </h2>

                <div className='flex justify-between my-2'>
                  <a className="inline-flex items-center font-medium text-blue-600 hover:text-blue-800 ">
                    {item.name}
                  </a>
                  <div className='text-gray-500 font-bold'>
                    Verified User
                  </div>
                </div>
                <p >{item.content}</p>
              </div>

              <div>

              </div>

            </div>
          </div>
        ))}
      </Carousel>







    </div>
  )
}

export default Review
import React from "react";

import { Button, Img, List, Text } from "components";
import Header from "components/Header";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "components/Footer";

const IQResultPage = () => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const time = localStorage.getItem("IqScore");
  console.log(time);
  let duration = "";
  let scoreresult = "";
  if (time) {
    const timeObject = JSON.parse(time);
    duration = timeObject.duration;
    scoreresult = timeObject.iqScore;
    //console.log(scoreresult);
  } else {
  }

  return (
    <>
      <Header className="bg-gray-200 flex sm:flex-col flex-row sm:gap-5 items-center justify-center md:px-5 shadow-bs2 w-full" />

      <div className="bg-white-A700 flex flex-col font-inter items-center justify-start mx-auto w-full">
        <div className="flex flex-col items-center justify-start w-full">
          <div className="bg-white-A700 flex flex-col items-center justify-start max-w-[1177px] mt-[45px] mx-auto p-[29px] md:px-5 rounded-[10px] shadow-bs5 w-full">
            <Img
              className="h-[485px] md:h-auto mb-1.5 mt-[19px] object-cover"
              src="images/img_group193.png"
              alt="group193"
            />
          </div>
          <div className="h-[491px]  mt-[67px] md:m-0 md:px-5 relative w-full">
            <Img
              className="h-[491px] m-auto object-cover w-full"
              src="images/img_checkoutherobg.png"
              alt="checkoutherobg"
            />
            <div className="absolute bottom-[3%] md:top-[10%] flex flex-col inset-x-[0] items-center justify-start mx-auto md:w-4/5 w-[48%]">
              <Text
                className="sm:text-[34px] md:text-[40px] text-[44px] text-black-900 text-center"
                size="txtInterSemiBold44"
              >
                <span
                  className="text-black-900 font-inter font-semibold"
                  style={{ textWrap: "nowrap" }}
                >
                  Well Done,{" "}
                </span>
                <span className="text-deep_purple-800_03 font-inter font-semibold">
                  {userState?.user?.name ?? ""}
                </span>
              </Text>
              <div className="border border-deep_purple-800_02 border-solid flex flex-col items-center justify-end mt-[17px] p-[11px] sm:w-[220px] w-[64%] md:w-full">
                <div className="flex flex-row gap-2.5 items-center justify-start w-[95%] md:w-full">
                  <Img
                    className="h-[34px] md:h-auto object-cover w-[8%]"
                    src="images/img_checkouticonaward.png"
                    alt="checkouticonawa"
                  />
                  <Text
                    className="text-gray-800_01 text-lg"
                    size="txtInterRegular18Gray80001"
                  >
                    <span className="text-gray-800_01 font-inter text-left font-normal">
                      You have scored highest in{" "}
                    </span>
                    <span className="text-deep_purple-900_01 font-inter text-left font-medium">
                      memory
                    </span>
                  </Text>
                </div>
              </div>
              <Text
                className="leading-[29.00px] mt-[21px] text-center text-gray-800_01 text-xl w-[95%] sm:w-full"
                size="txtInterRegular20"
              >
                <>
                  And you have completed the test in {duration}, that&#39;s
                  faster than 90% of people tested.
                </>
              </Text>
              <Text
                className="mt-[9px] text-2xl md:text-[22px] text-center text-deep_purple-800_02 sm:text-xl"
                size="txtInterSemiBold24"
              >
                Your IQ score is...
              </Text>


              
              <Text
                className="bg-clip-text bg-gradient2 blur-lg my-12  md:mb-0 md:text-5xl text-[80px] text-center text-transparent"
                size="txtInterBold80"
              >
                {scoreresult}
              </Text>
          
            </div>
          </div>
          <List
            className="sm:flex-col flex-row gap-6 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-center max-w-[1176px] mx-auto md:px-5 w-full"
            orientation="horizontal"
          >
            <div className="bg-white-A700 border border-gray-200_02 border-solid flex flex-1 flex-col items-center justify-end p-[23px] sm:px-5 shadow-bs4 w-full">
              <div className="flex flex-col gap-6 items-center justify-start mt-0.5 w-[96%] md:w-full">
                <Img
                  className="h-[76px] md:h-auto object-cover w-[76px]"
                  src="images/img_checkouticonhero1.png"
                  alt="checkouticonher"
                />
                <Text
                  className="leading-[24.00px] text-center text-gray-700 text-lg w-full"
                  size="txtInterRegular18Gray700"
                >
                  Trusted By 130,000 People Worldwide
                </Text>
              </div>
            </div>
            <div className="bg-white-A700 border border-gray-200_02 border-solid flex flex-1 flex-col items-center justify-end p-[23px] sm:px-5 shadow-bs4 w-full">
              <div className="flex flex-col gap-6 items-center justify-start mt-0.5 w-[96%] md:w-full">
                <Img
                  className="h-[76px] md:h-auto object-cover w-[76px]"
                  src="images/img_checkouticonhero2.png"
                  alt="checkouticonher"
                />
                <Text
                  className="leading-[24.00px] text-center text-gray-700 text-lg w-full"
                  size="txtInterRegular18Gray700"
                >
                  World’s Most Accurate IQ Scoring Technology
                </Text>
              </div>
            </div>
            <div className="bg-white-A700 border border-gray-200_02 border-solid flex flex-1 flex-col items-center justify-end p-6 sm:px-5 shadow-bs4 w-full">
              <div className="flex flex-col gap-[23px] items-center justify-start w-[96%] md:w-full">
                <Img
                  className="h-[76px] md:h-auto object-cover w-[76px]"
                  src="images/img_checkouticonhero3.png"
                  alt="checkouticonher"
                />
                <Text
                  className="leading-[24.00px] text-center text-gray-700 text-lg w-full"
                  size="txtInterRegular18Gray700"
                >
                  Accredited Certification Of Your Official IQ Score
                </Text>
              </div>
            </div>
          </List>
          <div className="flex flex-col items-center justify-start mt-[29px] md:px-5 w-1/5 md:w-full">
            <Button
              className="cursor-pointer font-semibold min-w-[253px] text-center text-lg"
              shape="round"
              size="md"
              variant="gradient"
              color="deep_purple_900_purple_400"
              onClick={() => navigate("/pricing")}
            >
              Reveal My IQ Results
            </Button>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default IQResultPage;
